<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="06" :question="question" @activateModal="modalActive=true" :modalActivator="true" :modalActive="modalActive"></QuestionTitle>
        <div class="container-70 mt-20px">
            <ActivityCalculator v-model="results"></ActivityCalculator>
            <div @click="saveAndContinue('touristActivity', JSON.stringify(results), '/resultats')" class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine  percent="100%" class="mt-50px"></AvancementLine>
        </div>
        <ModalInfo @closeModal="modalActive=false" v-if="modalActive">
            <h3 class="modal-title">GES émis par vos activités</h3>
            <p class="modal-text">Certaines activités ont une empreinte carbone plus élevée que d'autres. Par exemple, faire une randonnée dans un boisé est moins dommageable pour l'environnement que de s'y promener en VTT. Cette section permet d'évaluer les GES émis par les activités que vous réalisez durant votre séjour à Trois-Rivières.</p>
        </ModalInfo>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import ModalInfo from '@/components/ModalInfo.vue'
import ActivityCalculator from '@/components/ActivityCalculator.vue'
import AvancementLine from '@/components/AvancementLine.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default{
    data(){
        return {
            results:{

            },
            question: 'Détaillez le type d’activités réalisées durant le voyage à Trois-Rivières.',
            modalActive:false
        }
    },
    components:{
        QuestionTitle,
        ModalInfo,
        ActivityCalculator,
        AvancementLine
    },
    mixins:[utils]
}
</script>