<template>
  <div class="nb-adulte-container mt-10px">
    <div class="nb-adulte-img"><img :src="img" /></div>
    <div class="d-flex vertical-center d-flex-end p-60px">
      <div>
        <div class="d-flex vertical-center" v-for="(input, index) in listOfInputs" :key="index">
          <div>
            <img :src="minusImg" class="cursor-pointer" @click="removeTo(input.label)" />
          </div>
          <input type="number" v-model="results[input.label]" class="nb-adulte-input" @input="$emit('update:modelValue', results)"/>
          <div>
            <img :src="plusImg" class="cursor-pointer" @click="addTo(input.label)" />
          </div>
          <div>
            <div class="label-nb">{{ input.label }}</div>
            <div class="description-nb">{{ input.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import minusImg from "@/assets/images/minus.png";
import plusImg from "@/assets/images/plus.png";
export default {
  data() {
    return {
      minusImg,
      plusImg,
      results:{}
    };
  },
  props: {
    img: String,
    listOfInputs: Array
  },
  methods: {
    addTo(nb) {
      this.results[nb]++;
      this.$emit('update:modelValue', this.results)
    },
    removeTo(nb) {
      this.results[nb]--;
      this.$emit('update:modelValue', this.results)
    },
  },
  mounted(){
    this.listOfInputs.forEach(element=>{
      this.results[element.label]=0
    })
  }
};
</script>
<style>
.nb-adulte-container {
  position: relative;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-bottom-right-radius: 50px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
}
.nb-adulte-input {
  width: 75px;
  height: 35px;
  padding: 22px 35px 22px 35px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
  border: 3px solid var(--text-color);
  font-size: 42px;
  font-family: var(--font);
  text-align: center;
}

.label-nb {
  color: var(--text-color);
  text-align: right;
  font-family: var(--font);
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.description-nb {
  font-family: var(--font);
  color: var(--text-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.2px;
  /* 141% */
}
</style>
