<template>
    <div class="light-color-bg position-relative">
        <HeaderMain title="Évènement" :description="description"></HeaderMain>
        <div class="container-70 mt-20px">
            <CalculChoice :descriptions="descriptions" simpleLink="/evenement/simple/question-1" detailLink="/evenement/detaille/question-1"></CalculChoice>
        </div>
    </div>
</template>
<script>
import HeaderMain from '../../components/HeaderMain.vue'
import CalculChoice from '../../components/CalculChoice.vue'
export default{
    data(){
        return {
            description:"Nous vous offrons le choix d'estimer vos émissions de gaz à effet de serre (GES) selon notre calcul simplifié ou détaillé. ",
            descriptions:[
                'Le calcul simplifié (2 questions) utilise des informations partielles basées sur des moyennes. Le résultat global pourrait être surestimé.', 
                'Le calcul détaillé (8 questions) nécessite plus de données, ce qui le rend plus précis. '
            ]
        }
    },
    components:{
        HeaderMain,
        CalculChoice
    }
}
</script>