<template>
    <div class="select-option-container mt-10px">
      <div class="select-option-img"><img :src="img" /></div>
      <div class="p-60px flex-grow-1 d-flex vertical-center">
          <CustomSelect :options="options" class="select-position" v-model="result" @update:modelValue="(e)=>$emit('update:modelValue', e)"></CustomSelect>
      </div>
    </div>
  </template>
  <script>
  import CustomSelect from '@/components/form/CustomSelect.vue'
  export default {
    data() {
      return {
        result:null
      };
    },
    components:{
      CustomSelect
    },
    props: {
      img: String,
      options: Array
    },
    methods: {
      addTo(nb) {
        this.results[nb]++;
      },
      removeTo(nb) {
        this.results[nb]--;
      },
    }
  };
  </script>
  <style scoped>
  .select-option-container {
    position: relative;
    background-color: white;
    display: flex;
    border-bottom-right-radius: 50px;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
  }
  .description-nb {
    font-family: var(--font);
    color: var(--text-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.2px;
  }
  .select-position{
    width:80%;
    margin-left: auto;
    margin-right:auto;
  }
  .flex-grow-1{
    flex: 1;
    width:100%;
  }
  </style>
  