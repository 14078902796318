<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="07" :question="question"></QuestionTitle>
        <div class="container-70">
            <DoubleButtonChoice :button1="button1" :button2="button2" :bold="true" class="mt-100px" v-model="result" @update:modelValue="saveAndContinue('evenementTypeLieu', JSON.stringify(result.label), result.link)"></DoubleButtonChoice>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import DoubleButtonChoice from '@/components/DoubleButtonChoice.vue'
import AvancementLine from '@/components/AvancementLine.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            question:'Du point de vue des structures et des équipements, dans quel type de lieu tenez-vous votre évènement?',
            button1:{
                icon:require('@/assets/images/gallery.png'),
                link:'./question-8',
                label:'Une salle accueillant des évènements d’arts, de spectacles et de loisirs'
            },
            button2:{
                icon:require('@/assets/images/bistro.png'),
                link:'./question-8',
                label:'Un établissement de restauration ou d’hôtellerie'
            }
        }
    },
    components: {
        QuestionTitle,
        AvancementLine,
        DoubleButtonChoice
    },
    mixins:[utils]
}
</script>