<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="05" :question="question" @activateModal="modalActive=true" :modalActivator="true" :modalActive="modalActive"></QuestionTitle>
        <div class="container-70 mt-20px">
            <AlimentationCalculator v-model="results"></AlimentationCalculator>
            <div @click="saveAndContinue('touristAlimentation', JSON.stringify(results), './question-6')" class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine  percent="60%" class="mt-50px"></AvancementLine>
        </div>
        <ModalInfo @closeModal="modalActive=false" v-if="modalActive">
            <h3 class="modal-title">GES émis par votre alimentation</h3>
            <p class="modal-text">La production des protéines animales émet nettement plus de GES que celle des protéines végétales, notamment parce qu'elle nécessite plus de terres agricoles et plus d'eau. Parmi les protéines animales, les ruminants ont le plus grand impact environnemental, car ils relâchent du méthane dans l'atmosphère lors de leur digestion, soit un gaz 28 fois plus néfaste que le CO2. Cette section permet d'estimer l'impact environnemental de vos habitudes alimentaires selon le type de protéine que vous privilégiez.</p>
        </ModalInfo>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import ModalInfo from '@/components/ModalInfo.vue'
import AlimentationCalculator from '@/components/AlimentationCalculator.vue'
import AvancementLine from '@/components/AvancementLine.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default{
    data(){
        return {
            results:null,
            question: 'Spécifiez le profil alimentaire moyen des voyageurs.',
            modalActive:false
        }
    },
    components:{
        QuestionTitle,
        ModalInfo,
        AlimentationCalculator,
        AvancementLine
    },
    mixins:[utils]
}
</script>