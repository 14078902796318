<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="03" :question="question" @activateModal="modalActive=true" :modalActivator="true" :modalActive="modalActive"></QuestionTitle>
        <div class="container-70">
            <DoubleButtonChoice :button1="button1" :button2="button2" v-model="result" class="mt-100px" @update:modelValue="saveAndContinue('evenementDistanceChoice', JSON.stringify(result.label), result.link)" ></DoubleButtonChoice>
            <AccordeonBox :headerText="headerText" :bodyText="bodyText" class="mt-65px"></AccordeonBox>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
        <ModalInfo @closeModal="modalActive=false" v-if="modalActive">
            <h3 class="modal-title">GES émis par les transports de votre public</h3>
            <p class="modal-text">Au Québec, c'est le secteur des transports qui émet le plus de GES et contribue le plus au changement climatique. Cette section permet d'estimer les GES émis par les transports effectués dans le cadre de votre évènement. Les données seront recueillies en trois catégories : les personnes participantes à l'évènement (votre public), les organisateurs, partenaires, fournisseurs, bénévoles, et enfin les artistes, conférenciers ou invités.</p>
        </ModalInfo>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import DoubleButtonChoice from '@/components/DoubleButtonChoice.vue'
import AccordeonBox from '@/components/AccordeonBox.vue'
import AvancementLine from '@/components/AvancementLine.vue'
import ModalInfo from '@/components/ModalInfo.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            question:'Par quel moyen souhaitez-vous calculer les GES émis par les déplacements des personnes ayant assisté à votre évènement?',
            modalActive:false,
            button1:{
                link:'./question-3-option-a',
                label:'Avec la distance moyenne parcourue par personne'
            },
            button2:{
                link:'./question-3-option-b',
                label:'Avec les distances réelles parcourues par votre public'
            },
            headerText:'Besoin d’aide pour faire votre choix? ',
            bodyText:'À moins de soumettre un sondage que chaque individu doit obligatoirement remplir, il est normal qu\'il soit plus difficile d\'amasser les données pour les personnes participantes. En utilisant des estimations ou des moyennes, vous aurez quand même une idée approximative de l\'impact environnemental des déplacements de votre public pour assister à votre évènement.<br/>Pour comptabiliser des personnes qui utilisent l\'avion, veuillez privilégier les distances réelles. Cela évitera de calculer une distance moyenne avec de trop grands écarts.'
        }
    },
    components: {
        QuestionTitle,
        ModalInfo,
        AccordeonBox,
        AvancementLine,
        DoubleButtonChoice
    },
    mixins:[utils]
}
</script>