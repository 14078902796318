<template>
    <div class="accordeon">
        <div class="accordeon-header"><div>{{ headerText }} </div><CustomIcon name="chevron-up" v-if="bodyActive" @click="bodyActive=false" class="cursor-pointer"></CustomIcon><CustomIcon name="chevron-down" v-if="!bodyActive" @click="bodyActive=true" class="cursor-pointer"></CustomIcon></div>
        <div class="accordeon-body" v-html="bodyText" v-if="bodyActive"></div>
    </div>
</template>
<script>
import CustomIcon from './CustomIcon.vue';
export default{
    data(){
        return {
            bodyActive:false
        }
    },
    props:{
        headerText:String,
        bodyText: String
    },
    components:{
        CustomIcon
    }
}
</script>
<style>
.accordeon{
    background: #96D3DD;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
    color: var(--text-color);
    /* DESKTOP/H6 */
    font-family: var(--font);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.2px; /* 141% */
    padding:15px;
    box-sizing: border-box;
}
.accordeon-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.accordeon-body{
    margin-top:10px;
}
</style>