<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="08" :question="question" :comment="comment"></QuestionTitle>
        <div class="container-70">
            <SuperficieEvent></SuperficieEvent>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import AvancementLine from '@/components/AvancementLine.vue'
import SuperficieEvent from '@/components/SuperficieEvent.vue'
export default {
    data() {
        return {
            question:'Votre évènement couvre environ quelle superficie? ',
            comment:'Si l’évènement se déroule sur plusieurs étages, ne pas oublier de les additionner.'
        }
    },
    components: {
        QuestionTitle,
        SuperficieEvent,
        AvancementLine
    }
}
</script>