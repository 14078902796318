<template>
  <div>
    <svg
      width="77"
      height="72"
      viewBox="0 0 77 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      v-if="name === 'buble-1'"
    >
      <path
        opacity="0.25"
        d="M3.46035 29.9133C1.60247 33.1459 0.405864 36.6466 0.500333 40.7307C0.610546 45.414 2.01183 50.3496 4.0744 54.5441C8.24676 63.0592 16.623 68.8463 25.7077 71.0224C29.2345 71.8739 32.9188 72.1893 36.5401 71.8739C43.8457 71.2589 45.6091 65.188 49.2461 60.0316C53.7806 53.6137 62.7709 55.8056 69.1003 52.7307C77.3978 48.7096 78.5944 37.1512 73.3829 30.1814C69.4782 24.9619 61.5585 23.8266 58.63 19.6321C55.7172 15.4376 59.2126 9.31936 55.3551 2.98033C53.324 -0.331099 47.7189 -0.173412 44.444 0.1735C37.0754 0.946167 30.0533 4.28914 24.0073 8.43631C16.0089 13.9081 8.30974 21.4455 3.46035 29.9133Z"
        :fill="fill"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="72"
      viewBox="0 0 71 72"
      fill="none"
      v-if="name === 'buble-2'"
    >
      <path
        opacity="0.25"
        d="M1.73225 53.1904C2.6217 56.6816 4.216 59.9451 7.23678 62.7684C10.7107 66.0015 15.3761 68.582 20.008 70.1909C29.4227 73.4696 40.1632 72.0883 48.8396 67.6864C52.2128 65.9712 55.3175 63.7854 57.9355 61.2049C63.2051 55.9833 60.2011 50.5341 59.3284 44.5232C58.2376 37.0247 66.8636 32.6835 69.5823 26.369C73.1569 18.0964 65.7559 9.1408 56.6265 7.62289C49.7962 6.48446 42.7813 10.8712 37.4613 9.80868C32.1414 8.74615 30.4632 2.12808 22.8609 0.169982C18.8835 -0.847014 14.6209 2.93257 12.305 5.31568C7.10253 10.6739 4.01462 17.6411 2.28606 24.5324C-0.0130861 33.6398 -0.600454 44.0071 1.71548 53.16L1.73225 53.1904Z"
        fill="#96D3DD"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="72"
      viewBox="0 0 77 72"
      fill="none"
      v-if="name === 'buble-3'"
    >
      <path
        opacity="0.25"
        d="M15.767 5.8857C12.1896 7.60575 8.99324 10.0319 6.5421 13.8704C3.72661 18.2701 1.82201 23.7924 0.960796 29.0793C-0.81131 39.7979 2.66666 50.8605 9.0098 59.0625C11.4775 62.2491 14.3924 65.0555 17.6385 67.2463C24.2135 71.6641 29.4801 67.2463 35.757 64.965C43.5907 62.1224 49.851 70.2881 57.1216 71.7547C66.6612 73.692 74.8592 63.8243 74.793 53.8299C74.7433 46.3341 68.7314 39.8884 68.8805 34.0221C69.0295 28.1558 75.8032 24.8787 76.4823 16.3871C76.8301 11.9511 71.9775 8.27566 68.9964 6.35644C62.2723 2.04725 54.2399 0.345309 46.5387 0.0556154C36.3532 -0.342713 25.1409 1.37734 15.7504 5.9038L15.767 5.8857Z"
        fill="#96D3DD"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="72"
      viewBox="0 0 76 72"
      fill="none"
      v-if="name === 'buble-4'"
    >
      <path
        opacity="0.25"
        d="M55.6005 70.5885C59.2434 69.7137 62.6341 68.1185 65.5832 65.0481C68.9738 61.5318 71.6864 56.7804 73.4054 52.0634C76.8906 42.4749 75.5344 31.4799 71.024 22.5604C69.2735 19.0955 67.0341 15.9051 64.3531 13.2121C58.9753 7.77465 53.298 10.7936 47.0371 11.634C39.2307 12.6804 34.7834 3.82948 28.2544 0.982102C19.6753 -2.75723 10.3391 4.73858 8.68323 14.0526C7.45313 21.0167 11.9477 28.238 10.8122 33.6583C9.67677 39.0787 2.78506 40.7425 0.687587 48.4956C-0.400578 52.5437 3.49474 56.952 5.95493 59.3362C11.4904 64.7051 18.6975 67.9127 25.8415 69.7652C35.2881 72.2009 46.0593 72.887 55.5847 70.6057L55.6005 70.5885Z"
        fill="#96D3DD"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="73"
      viewBox="0 0 77 73"
      fill="none"
      v-if="name === 'buble-5'"
    >
      <path
        opacity="0.25"
        d="M75.4583 34.2489C76.5452 30.8101 76.9288 27.2496 75.8899 23.4457C74.7071 19.0636 72.1817 14.7423 69.1768 11.2427C63.087 4.15212 53.4809 0.545983 44.0026 0.500335C40.3104 0.48512 36.6182 0.987238 33.1018 2.06756C26.021 4.24341 25.6694 10.3449 23.2719 15.9748C20.2669 23.0045 10.8686 22.9132 5.32225 27.1888C-1.95029 32.7882 -0.479801 43.9109 6.28127 49.3277C11.3481 53.3751 19.4358 52.7056 23.2878 56.0074C27.1399 59.3093 25.078 65.8368 30.3526 70.9493C33.1178 73.6273 38.6161 72.2427 41.7809 71.1928C48.8776 68.8496 55.0633 64.1631 60.0821 58.9441C66.7313 52.0513 72.6133 43.2718 75.4583 34.2489Z"
        fill="#96D3DD"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="38"
      viewBox="0 0 70 38"
      fill="none"
      v-if="name === 'sous-compacte'"
    >
      <path
        d="M49.4023 31.1879H21.0617C20.4162 31.1879 19.8809 30.6518 19.8809 30.0053C19.8809 29.3587 20.4162 28.8226 21.0617 28.8226H49.4023C50.0478 28.8226 50.5832 29.3587 50.5832 30.0053C50.5832 30.6518 50.0478 31.1879 49.4023 31.1879Z"
        fill="#3B3B3C"
      />
      <path
        d="M14.7639 37.4954C10.6388 37.4954 7.28516 34.1367 7.28516 30.0053C7.28516 25.8739 10.6388 22.5151 14.7639 22.5151C18.8891 22.5151 22.2427 25.8739 22.2427 30.0053C22.2427 34.1367 18.8891 37.4954 14.7639 37.4954ZM14.7639 24.8804C11.9456 24.8804 9.64687 27.1827 9.64687 30.0053C9.64687 32.8279 11.9456 35.1301 14.7639 35.1301C17.5822 35.1301 19.881 32.8279 19.881 30.0053C19.881 27.1827 17.5822 24.8804 14.7639 24.8804Z"
        fill="#3B3B3C"
      />
      <path
        d="M57.2747 37.4954C53.1495 37.4954 49.7959 34.1367 49.7959 30.0053C49.7959 25.8738 53.1495 22.5151 57.2747 22.5151C61.3998 22.5151 64.7534 25.8738 64.7534 30.0053C64.7534 34.1367 61.3998 37.4954 57.2747 37.4954ZM57.2747 24.8804C54.4563 24.8804 52.1576 27.1827 52.1576 30.0053C52.1576 32.8279 54.4563 35.1301 57.2747 35.1301C60.093 35.1301 62.3917 32.8279 62.3917 30.0053C62.3917 27.1827 60.093 24.8804 57.2747 24.8804Z"
        fill="#3B3B3C"
      />
      <path
        d="M47.8281 15.4192H16.3386C15.693 15.4192 15.1577 14.883 15.1577 14.2365C15.1577 13.59 15.693 13.0539 16.3386 13.0539H47.8281C48.4736 13.0539 49.009 13.59 49.009 14.2365C49.009 14.883 48.4736 15.4192 47.8281 15.4192Z"
        fill="#3B3B3C"
      />
      <path
        d="M63.5729 31.1879C63.0848 31.1879 62.6124 30.8725 62.455 30.3837C62.2503 29.7687 62.581 29.0907 63.195 28.8857L67.1155 27.5769V19.929C67.1155 19.0145 66.4699 18.2103 65.5725 18.0053L55.6533 15.7976C54.2677 15.4823 52.9924 14.7884 51.9847 13.7792L42.0655 3.84494C41.4042 3.18265 40.4911 2.8042 39.5621 2.8042H22.1012C20.8731 2.8042 19.7552 3.43496 19.0939 4.47569L13.9926 12.6439C12.9062 14.3784 11.0326 15.4192 8.98577 15.4192H5.30149C4.2151 15.4192 3.3334 16.3022 3.3334 17.3903V27.498L8.73385 28.8541C9.36364 29.0118 9.75726 29.6583 9.59981 30.2891C9.44236 30.9198 8.79683 31.3141 8.16704 31.1564L1.86913 29.5795C1.34955 29.4533 0.97168 28.9803 0.97168 28.4284V17.3903C0.97168 14.9934 2.90829 13.0539 5.30149 13.0539H8.98577C10.2139 13.0539 11.3317 12.4231 11.993 11.3824L17.0943 3.21419C18.1807 1.47963 20.0543 0.438896 22.1012 0.438896H39.5621C41.1208 0.438896 42.6323 1.06965 43.7345 2.17346L53.6537 12.1078C54.3464 12.8016 55.2124 13.2746 56.1571 13.4796L66.0763 15.6873C68.0759 16.1288 69.4614 17.8791 69.4614 19.9133V28.4284C69.4614 28.933 69.1308 29.3903 68.6584 29.548L63.935 31.1248C63.8091 31.1721 63.6831 31.1879 63.5571 31.1879H63.5729Z"
        fill="#3B3B3C"
      />
      <path
        d="M47.8283 26.4573H24.2111C23.5656 26.4573 23.0303 25.9212 23.0303 25.2746C23.0303 24.6281 23.5656 24.092 24.2111 24.092H47.8283C48.4738 24.092 49.0091 24.6281 49.0091 25.2746C49.0091 25.9212 48.4738 26.4573 47.8283 26.4573Z"
        fill="#3B3B3C"
      />
      <path
        d="M28.9343 13.8423C28.2887 13.8423 27.7534 13.3062 27.7534 12.6597V4.77529C27.7534 4.12878 28.2887 3.59264 28.9343 3.59264C29.5798 3.59264 30.1151 4.12878 30.1151 4.77529V12.6597C30.1151 13.3062 29.5798 13.8423 28.9343 13.8423Z"
        fill="#3B3B3C"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="36"
      viewBox="0 0 74 36"
      fill="none"
      v-if="name === 'compacte'"
    >
      <path
        d="M52.4354 29.876H22.0343C21.3418 29.876 20.7676 29.3605 20.7676 28.7388C20.7676 28.1171 21.3418 27.6016 22.0343 27.6016H52.4354C53.1278 27.6016 53.7021 28.1171 53.7021 28.7388C53.7021 29.3605 53.1278 29.876 52.4354 29.876Z"
        fill="#3B3B3C"
      />
      <path
        d="M15.2784 35.9412C10.8533 35.9412 7.25586 32.7115 7.25586 28.7388C7.25586 24.7661 10.8533 21.5364 15.2784 21.5364C19.7034 21.5364 23.3009 24.7661 23.3009 28.7388C23.3009 32.7115 19.7034 35.9412 15.2784 35.9412ZM15.2784 23.8108C12.2551 23.8108 9.78928 26.0246 9.78928 28.7388C9.78928 31.453 12.2551 33.6668 15.2784 33.6668C18.3016 33.6668 20.7674 31.453 20.7674 28.7388C20.7674 26.0246 18.3016 23.8108 15.2784 23.8108Z"
        fill="#3B3B3C"
      />
      <path
        d="M60.8799 35.9413C56.4549 35.9413 52.8574 32.7115 52.8574 28.7388C52.8574 24.7661 56.4549 21.5364 60.8799 21.5364C65.305 21.5364 68.9024 24.7661 68.9024 28.7388C68.9024 32.7115 65.305 35.9413 60.8799 35.9413ZM60.8799 23.8109C57.8567 23.8109 55.3908 26.0247 55.3908 28.7388C55.3908 31.453 57.8567 33.6668 60.8799 33.6668C63.9031 33.6668 66.369 31.453 66.369 28.7388C66.369 26.0247 63.9031 23.8109 60.8799 23.8109Z"
        fill="#3B3B3C"
      />
      <path
        d="M60.8798 14.713H60.8461L6.79971 13.1967C6.10724 13.1816 5.54989 12.6509 5.56678 12.0292C5.58367 11.4075 6.20859 10.892 6.86728 10.9223L60.9136 12.4386C61.6061 12.4538 62.1634 12.9845 62.1466 13.6061C62.1297 14.2278 61.5554 14.713 60.8798 14.713Z"
        fill="#3B3B3C"
      />
      <path
        d="M67.6357 29.8761C67.1121 29.8761 66.6054 29.5728 66.4365 29.1027C66.217 28.5114 66.5717 27.8594 67.2304 27.6623L69.9833 26.8435C70.8447 26.5857 71.419 25.8579 71.419 25.0391V18.898C71.419 18.0489 70.794 17.3059 69.882 17.0785L61.0826 14.8192C60.559 14.6827 60.0692 14.4704 59.6301 14.1823L42.4197 2.94655C42.065 2.70394 41.6259 2.58263 41.1868 2.58263H16.8828C16.241 2.58263 15.633 2.8404 15.2276 3.29529L3.47254 16.4871C3.16853 16.8207 3.01654 17.2453 3.01654 17.6698V24.8571C3.01654 25.7366 3.67523 26.4795 4.62104 26.6918L8.8265 27.6319C9.50208 27.7836 9.92433 28.4052 9.75543 29.0118C9.58654 29.6183 8.89406 29.9974 8.21848 29.8457L4.01302 28.9056C1.93561 28.4356 0.5 26.7828 0.5 24.8571V17.6698C0.5 16.7297 0.85469 15.8048 1.51338 15.0618L13.2684 1.86997C14.1636 0.869215 15.481 0.308174 16.8997 0.308174H41.2037C42.1833 0.308174 43.1291 0.581118 43.9398 1.11182L61.1502 12.3476C61.3528 12.4841 61.5724 12.5751 61.8088 12.6357L70.6082 14.895C72.5843 15.3954 73.9861 17.0481 73.9861 18.898V25.0391C73.9861 26.8435 72.7026 28.4204 70.8109 28.9966L68.0579 29.8154C67.9228 29.8609 67.7877 29.8761 67.6526 29.8761H67.6357Z"
        fill="#3B3B3C"
      />
      <path
        d="M50.7464 25.3271H25.4122C24.7198 25.3271 24.1455 24.8116 24.1455 24.1899C24.1455 23.5682 24.7198 23.0527 25.4122 23.0527H50.7464C51.4389 23.0527 52.0132 23.5682 52.0132 24.1899C52.0132 24.8116 51.4389 25.3271 50.7464 25.3271Z"
        fill="#3B3B3C"
      />
      <path
        d="M37.2345 13.1967C36.542 13.1967 35.9678 12.6812 35.9678 12.0595V4.47799C35.9678 3.85631 36.542 3.34077 37.2345 3.34077C37.927 3.34077 38.5012 3.85631 38.5012 4.47799V12.0595C38.5012 12.6812 37.927 13.1967 37.2345 13.1967Z"
        fill="#3B3B3C"
      />
      <path
        d="M18.6564 13.1967C17.9639 13.1967 17.3896 12.6812 17.3896 12.0595V4.47799C17.3896 3.85631 17.9639 3.34077 18.6564 3.34077C19.3488 3.34077 19.9231 3.85631 19.9231 4.47799V12.0595C19.9231 12.6812 19.3488 13.1967 18.6564 13.1967Z"
        fill="#3B3B3C"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="43"
      viewBox="0 0 72 43"
      fill="none"
      v-if="name === 'berline'"
    >
      <path
        d="M50.8981 35.421H21.2542C20.579 35.421 20.019 34.804 20.019 34.0601C20.019 33.3161 20.579 32.6991 21.2542 32.6991H50.8981C51.5733 32.6991 52.1332 33.3161 52.1332 34.0601C52.1332 34.804 51.5733 35.421 50.8981 35.421Z"
        fill="#3B3B3C"
      />
      <path
        d="M14.6669 42.6794C10.3521 42.6794 6.84424 38.8143 6.84424 34.0601C6.84424 29.3058 10.3521 25.4408 14.6669 25.4408C18.9818 25.4408 22.4896 29.3058 22.4896 34.0601C22.4896 38.8143 18.9818 42.6794 14.6669 42.6794ZM14.6669 28.1627C11.719 28.1627 9.31456 30.812 9.31456 34.0601C9.31456 37.3082 11.719 39.9575 14.6669 39.9575C17.6148 39.9575 20.0193 37.3082 20.0193 34.0601C20.0193 30.812 17.6148 28.1627 14.6669 28.1627Z"
        fill="#3B3B3C"
      />
      <path
        d="M59.1327 42.6794C54.8179 42.6794 51.3101 38.8143 51.3101 34.0601C51.3101 29.3059 54.8179 25.4408 59.1327 25.4408C63.4476 25.4408 66.9554 29.3059 66.9554 34.0601C66.9554 38.8143 63.4476 42.6794 59.1327 42.6794ZM59.1327 28.1627C56.1848 28.1627 53.7804 30.812 53.7804 34.0601C53.7804 37.3082 56.1848 39.9575 59.1327 39.9575C62.0807 39.9575 64.4851 37.3082 64.4851 34.0601C64.4851 30.812 62.0807 28.1627 59.1327 28.1627Z"
        fill="#3B3B3C"
      />
      <path
        d="M49.2513 17.2751H6.43243C5.75721 17.2751 5.19727 16.6582 5.19727 15.9142C5.19727 15.1702 5.75721 14.5533 6.43243 14.5533H49.2513C49.9266 14.5533 50.4865 15.1702 50.4865 15.9142C50.4865 16.6582 49.9266 17.2751 49.2513 17.2751Z"
        fill="#3B3B3C"
      />
      <path
        d="M65.7204 35.421C65.2098 35.421 64.7157 35.0581 64.5511 34.4956C64.337 33.7879 64.6828 33.0076 65.3251 32.7717L69.4258 31.2656V22.4649C69.4258 21.3942 68.7671 20.487 67.8119 20.2511L57.4365 17.7106C55.9873 17.3477 54.6533 16.5493 53.5993 15.388L43.224 3.95604C42.5323 3.19391 41.5935 2.7584 40.6054 2.7584H10.1052C9.31467 2.7584 8.60652 3.2302 8.26068 4.01047L2.92478 15.7509C2.77656 16.0594 2.71069 16.4223 2.71069 16.767V29.4147C2.71069 30.4672 3.35296 31.3563 4.27522 31.6104L8.37596 32.7354C9.03471 32.9169 9.44642 33.6609 9.28174 34.3867C9.11705 35.1125 8.44183 35.5662 7.78308 35.3847L3.68235 34.2597C1.65669 33.6972 0.256836 31.7193 0.256836 29.4147V16.767C0.256836 16.0049 0.421526 15.2246 0.734433 14.5351L6.07032 2.7947C6.84436 1.08898 8.39242 0.0365143 10.1216 0.0365143H40.6219C42.2688 0.0365143 43.8168 0.744216 44.9861 2.03257L55.3615 13.4645C56.0861 14.2629 56.9919 14.8073 57.98 15.0432L68.3553 17.5836C70.4469 18.0917 71.8962 20.1059 71.8962 22.4467V32.2455C71.8962 32.8262 71.5503 33.3524 71.0563 33.5339L66.1156 35.3485C65.9839 35.4029 65.8521 35.421 65.7204 35.421Z"
        fill="#3B3B3C"
      />
      <path
        d="M49.2514 29.9772H24.5481C23.8729 29.9772 23.313 29.3603 23.313 28.6163C23.313 27.8723 23.8729 27.2553 24.5481 27.2553H49.2514C49.9266 27.2553 50.4865 27.8723 50.4865 28.6163C50.4865 29.3603 49.9266 29.9772 49.2514 29.9772Z"
        fill="#3B3B3C"
      />
      <path
        d="M32.7825 15.4605C32.1073 15.4605 31.5474 14.8436 31.5474 14.0996V5.02662C31.5474 4.28264 32.1073 3.66568 32.7825 3.66568C33.4577 3.66568 34.0177 4.28264 34.0177 5.02662V14.0996C34.0177 14.8436 33.4577 15.4605 32.7825 15.4605Z"
        fill="#3B3B3C"
      />
      <path
        d="M16.3138 15.4605C15.6386 15.4605 15.0786 14.8436 15.0786 14.0996V5.02662C15.0786 4.28264 15.6386 3.66568 16.3138 3.66568C16.989 3.66568 17.5489 4.28264 17.5489 5.02662V14.0996C17.5489 14.8436 16.989 15.4605 16.3138 15.4605Z"
        fill="#3B3B3C"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="48"
      viewBox="0 0 70 48"
      fill="none"
      v-if="name === 'vus-compact'"
    >
      <path
        d="M52.5358 19.7657H39.9116C39.2646 19.7657 38.728 19.1867 38.728 18.4885V6.56802C38.728 5.86983 39.2646 5.29083 39.9116 5.29083H46.2237C46.6498 5.29083 47.0443 5.52924 47.2494 5.93794L53.5616 17.8584C53.7667 18.2501 53.7667 18.7439 53.5616 19.1356C53.3564 19.5273 52.9619 19.7657 52.5358 19.7657ZM41.0951 17.2113H50.5002L45.5451 7.84522H41.1109V17.2113H41.0951Z"
        fill="#3B3B3C"
      />
      <path
        d="M47.8018 40.2008H24.1313C23.4843 40.2008 22.9478 39.6218 22.9478 38.9236C22.9478 38.2254 23.4843 37.6464 24.1313 37.6464H47.8018C48.4488 37.6464 48.9853 38.2254 48.9853 38.9236C48.9853 39.6218 48.4488 40.2008 47.8018 40.2008Z"
        fill="#3B3B3C"
      />
      <path
        d="M65.1602 40.2007H62.0041C61.3571 40.2007 60.8206 39.6217 60.8206 38.9235C60.8206 38.2253 61.3571 37.6463 62.0041 37.6463H65.1602C66.249 37.6463 67.1327 36.6927 67.1327 35.5177V24.3465C67.1327 23.4269 66.5962 22.6095 65.7756 22.32L59.5739 20.0892C58.5955 19.7316 57.7592 19.0164 57.2227 18.0627L49.2694 3.77519C48.9222 3.14511 48.2752 2.73642 47.5809 2.73642H9.92895C8.84011 2.73642 7.95641 3.69006 7.95641 4.86508V35.5177C7.95641 36.6927 8.84011 37.6463 9.92895 37.6463H11.507C12.154 37.6463 12.6905 38.2253 12.6905 38.9235C12.6905 39.6217 12.154 40.2007 11.507 40.2007H9.92895C7.53034 40.2007 5.58936 38.1061 5.58936 35.5177V4.86508C5.58936 2.27663 7.53034 0.182037 9.92895 0.182037H47.5966C49.1116 0.182037 50.5318 1.05052 51.3208 2.44692L59.2741 16.7344C59.5108 17.1772 59.8895 17.5008 60.3314 17.654L66.5331 19.8849C68.3005 20.532 69.4998 22.303 69.4998 24.3295V35.5007C69.4998 38.0891 67.5588 40.1837 65.1602 40.1837V40.2007Z"
        fill="#3B3B3C"
      />
      <path
        d="M17.8189 47.0125C13.6845 47.0125 10.3232 43.3853 10.3232 38.9236C10.3232 34.4619 13.6845 30.8347 17.8189 30.8347C21.9534 30.8347 25.3146 34.4619 25.3146 38.9236C25.3146 43.3853 21.9534 47.0125 17.8189 47.0125ZM17.8189 33.3891C14.9942 33.3891 12.6903 35.8754 12.6903 38.9236C12.6903 41.9718 14.9942 44.4581 17.8189 44.4581C20.6436 44.4581 22.9475 41.9718 22.9475 38.9236C22.9475 35.8754 20.6436 33.3891 17.8189 33.3891Z"
        fill="#3B3B3C"
      />
      <path
        d="M55.692 47.0125C51.5575 47.0125 48.1963 43.3853 48.1963 38.9236C48.1963 34.4619 51.5575 30.8347 55.692 30.8347C59.8264 30.8347 63.1876 34.4619 63.1876 38.9236C63.1876 43.3853 59.8264 47.0125 55.692 47.0125ZM55.692 33.3891C52.8673 33.3891 50.5633 35.8754 50.5633 38.9236C50.5633 41.9718 52.8673 44.4581 55.692 44.4581C58.5166 44.4581 60.8206 41.9718 60.8206 38.9236C60.8206 35.8754 58.5166 33.3891 55.692 33.3891Z"
        fill="#3B3B3C"
      />
      <path
        d="M35.1776 19.7657H25.7094C25.0624 19.7657 24.5259 19.1867 24.5259 18.4885V6.56802C24.5259 5.86983 25.0624 5.29083 25.7094 5.29083H35.1776C35.8246 5.29083 36.3611 5.86983 36.3611 6.56802V18.4885C36.3611 19.1867 35.8246 19.7657 35.1776 19.7657ZM26.8929 17.2113H33.9941V7.84522H26.8929V17.2113Z"
        fill="#3B3B3C"
      />
      <path
        d="M20.975 19.7657H11.5068C10.8598 19.7657 10.3232 19.1867 10.3232 18.4885V6.56802C10.3232 5.86983 10.8598 5.29083 11.5068 5.29083H20.975C21.622 5.29083 22.1585 5.86983 22.1585 6.56802V18.4885C22.1585 19.1867 21.622 19.7657 20.975 19.7657ZM12.6903 17.2113H19.7915V7.84522H12.6903V17.2113Z"
        fill="#3B3B3C"
      />
      <path
        d="M62.0039 26.5774H11.5068C10.8598 26.5774 10.3232 25.9984 10.3232 25.3002C10.3232 24.602 10.8598 24.023 11.5068 24.023H62.0039C62.6509 24.023 63.1874 24.602 63.1874 25.3002C63.1874 25.9984 62.6509 26.5774 62.0039 26.5774Z"
        fill="#3B3B3C"
      />
      <path
        d="M6.7731 29.9832H2.03899C1.392 29.9832 0.855469 29.4042 0.855469 28.706V13.3797C0.855469 12.6815 1.392 12.1025 2.03899 12.1025H6.7731C7.42009 12.1025 7.95663 12.6815 7.95663 13.3797V28.706C7.95663 29.4042 7.42009 29.9832 6.7731 29.9832ZM3.22252 27.4288H5.58957V14.6569H3.22252V27.4288Z"
        fill="#3B3B3C"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="43"
      viewBox="0 0 66 43"
      fill="none"
      v-if="name === 'vus-grand'"
    >
      <path
        d="M56.5955 18.2282H43.9546C43.3068 18.2282 42.7695 17.7126 42.7695 17.091V6.47686C42.7695 5.85517 43.3068 5.33963 43.9546 5.33963H50.2751C50.7017 5.33963 51.0967 5.55192 51.3022 5.91583L57.6226 16.5299C57.828 16.8787 57.828 17.3184 57.6226 17.6672C57.4172 18.0159 57.0222 18.2282 56.5955 18.2282ZM45.1397 15.9537H54.5572L49.5956 7.61408H45.1555V15.9537H45.1397Z"
        fill="#3B3B3C"
      />
      <path
        d="M43.9547 36.4238H18.6729C18.025 36.4238 17.4878 35.9083 17.4878 35.2866C17.4878 34.6649 18.025 34.1494 18.6729 34.1494H43.9547C44.6026 34.1494 45.1398 34.6649 45.1398 35.2866C45.1398 35.9083 44.6026 36.4238 43.9547 36.4238Z"
        fill="#3B3B3C"
      />
      <path
        d="M61.3359 36.4239H58.1757C57.5278 36.4239 56.9906 35.9083 56.9906 35.2866C56.9906 34.6649 57.5278 34.1494 58.1757 34.1494H61.3359C62.4262 34.1494 63.3111 33.3003 63.3111 32.254V20.9424C63.3111 20.6088 63.2163 20.2753 63.0424 19.9872L53.3247 4.00534C52.9771 3.42914 52.3135 3.06522 51.6182 3.06522H4.45176C3.36148 3.06522 2.47662 3.91435 2.47662 4.9606V34.1494H6.03188C6.67973 34.1494 7.21696 34.6649 7.21696 35.2866C7.21696 35.9083 6.67973 36.4239 6.03188 36.4239H1.29153C0.643685 36.4239 0.106445 35.9083 0.106445 35.2866V4.9606C0.106445 2.65582 2.04999 0.790771 4.45176 0.790771H51.6182C53.1509 0.790771 54.5888 1.57925 55.3789 2.85294L65.0966 18.8348C65.4916 19.4716 65.697 20.1994 65.697 20.9424V32.254C65.697 34.5588 63.7535 36.4239 61.3517 36.4239H61.3359Z"
        fill="#3B3B3C"
      />
      <path
        d="M12.3522 42.4891C8.21233 42.4891 4.84668 39.2593 4.84668 35.2866C4.84668 31.3139 8.21233 28.0842 12.3522 28.0842C16.4921 28.0842 19.8578 31.3139 19.8578 35.2866C19.8578 39.2593 16.4921 42.4891 12.3522 42.4891ZM12.3522 30.3586C9.52382 30.3586 7.21685 32.5724 7.21685 35.2866C7.21685 38.0008 9.52382 40.2146 12.3522 40.2146C15.1806 40.2146 17.4876 38.0008 17.4876 35.2866C17.4876 32.5724 15.1806 30.3586 12.3522 30.3586Z"
        fill="#3B3B3C"
      />
      <path
        d="M51.8552 42.4891C47.7153 42.4891 44.3496 39.2593 44.3496 35.2866C44.3496 31.3139 47.7153 28.0842 51.8552 28.0842C55.9951 28.0842 59.3607 31.3139 59.3607 35.2866C59.3607 39.2593 55.9951 42.4891 51.8552 42.4891ZM51.8552 30.3586C49.0268 30.3586 46.7198 32.5724 46.7198 35.2866C46.7198 38.0008 49.0268 40.2146 51.8552 40.2146C54.6836 40.2146 56.9905 38.0008 56.9905 35.2866C56.9905 32.5724 54.6836 30.3586 51.8552 30.3586Z"
        fill="#3B3B3C"
      />
      <path
        d="M37.6341 18.2282H24.9932C24.3453 18.2282 23.8081 17.7127 23.8081 17.091V6.47689C23.8081 5.8552 24.3453 5.33966 24.9932 5.33966H37.6341C38.282 5.33966 38.8192 5.8552 38.8192 6.47689V17.091C38.8192 17.7127 38.282 18.2282 37.6341 18.2282ZM26.1783 15.9538H36.449V7.61411H26.1783V15.9538Z"
        fill="#3B3B3C"
      />
      <path
        d="M18.6727 18.2282H6.03177C5.38392 18.2282 4.84668 17.7127 4.84668 17.091V6.47689C4.84668 5.8552 5.38392 5.33966 6.03177 5.33966H18.6727C19.3205 5.33966 19.8578 5.8552 19.8578 6.47689V17.091C19.8578 17.7127 19.3205 18.2282 18.6727 18.2282ZM7.21685 15.9538H17.4876V7.61411H7.21685V15.9538Z"
        fill="#3B3B3C"
      />
      <path
        d="M58.1756 24.2934H6.03177C5.38392 24.2934 4.84668 23.7779 4.84668 23.1562C4.84668 22.5345 5.38392 22.019 6.03177 22.019H58.1756C58.8234 22.019 59.3607 22.5345 59.3607 23.1562C59.3607 23.7779 58.8234 24.2934 58.1756 24.2934Z"
        fill="#3B3B3C"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      v-if="name === 'chevron-up'"
    >
      <path
        d="M7.08342 21.25L17.0001 11.3333L26.9167 21.25"
        stroke="#14181F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      v-if="name === 'chevron-down'"
    >
      <path
        d="M26.9166 12.75L16.9999 22.6667L7.08325 12.75"
        stroke="#14181F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      v-if="name === 'garbage'"
    >
      <path
        d="M12.1428 9.91667V6.8C12.1428 6.1237 12.3986 5.4751 12.8541 4.99688C13.3095 4.51866 13.9272 4.25 14.5713 4.25L19.4285 4.25C20.0726 4.25 20.6903 4.51866 21.1457 4.99688C21.6012 5.4751 21.8571 6.1237 21.8571 6.8V9.91667M26.9166 9.91667L25.4999 27.2C25.4999 27.8763 25.2441 28.5249 24.7886 29.0031C24.3332 29.4813 23.7154 29.75 23.0713 29.75L10.9285 29.75C10.2844 29.75 9.66668 29.4813 9.21123 29.0031C8.75579 28.5249 8.49992 27.8763 8.49992 27.2L7.08325 9.91667L26.9166 9.91667Z"
        stroke="#3B3B3C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.66675 9.91602H8.18527L28.3334 9.91602"
        stroke="#3B3B3C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1667 15.584V22.6673"
        stroke="#3B3B3C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.8333 15.584V22.6673"
        stroke="#3B3B3C"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" v-if="name === 'close'">
<path d="M1.77832 1L15.9997 15.2187M1.77832 15.2187L15.9997 1" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    fill: String,
  },
};
</script>
