<template>
    <div v-if="vehicule">
        <label class="label-calculators">{{ vehicule.type }}</label>
        <div class="d-flex vertical-center space-between">
            <div class="col-20">
                <img :src="vehicule.icon" class="w-100"/>
            </div>
            <div class="position-relative col-25">
                <input type="number" class="distance-calculator-input w-100" placeholder="À l'aller" v-model="results['aller']" @input="$emit('update:modelValue', results)"/>
                <div class="km">KM</div>
            </div>
            <div class="position-relative col-25">
                <input type="number" class="distance-calculator-input w-100" placeholder="Au retour" v-model="results['retour']" @input="$emit('update:modelValue', results)"/>
                <div class="km">KM</div>
            </div>
            <div class="position-relative col-25" v-if="!plane">
                <input type="number" class="distance-calculator-input w-100" placeholder="À Trois-Rivières" v-model="results['troisRivieres']" @input="$emit('update:modelValue', results)"/>
                <div class="km">KM</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            results:{
                aller:null,
                retour:null,
                troisRivieres:null
            }
        }
    },
    props: {
        vehicule: Object,
        plane:{type:Boolean, default:false}
    }
}
</script>
<style>
.label-calculators{
    color: var(--color);
    font-family: var(--font);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.2px;
}
.distance-calculator-input {
    display: flex;
    height: 57px;
    padding-left: 12px;
    padding-right: 40px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 10px;
    border: 2px solid var(--Secondary-Noir, #3B3B3C);
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
    text-align: end;
    box-sizing:border-box;
}
.distance-calculator-input::placeholder{
    color: #bab1b1;
    font-family: var(--font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.2px; /* 235% */
}
.km {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-color);
    font-family: var(--font);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.2px;
    /* 141% */
}
.col-20{
    width:20%;
}
.col-25{
    width:25%;
}
</style>