<template>
  <div class="alimentation-calculator-container">
    <div>
      <p class="long-text">Indiquez les proportions qui représentent le mieux le profil alimentaire des voyageurs. Par exemple, si toutes les personnes sont omnivores, indiquez 100% omnivore. Si un couple est composé d’une personne consommatrice de viande à chaque repas et d’une personne végétarienne, indiquez 50% viande à tous les repas et 50% végétarien.</p>
    </div>
    <div class="row">
      <div class="col-40"><img :src="imgAlimentation"/></div>
      <div class="col-60">
        <div v-for="(alimentationType, index) in alimentationTypes" :key="index">
          <AlimentationInput :label="alimentationType" v-model="results[alimentationType]" @update:modelValue="$emit('update:modelValue', results)"></AlimentationInput>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AlimentationInput from '@/components/form/AlimentationInput'
export default{
  data(){
    return {
      results:{

      },
      imgAlimentation:require('@/assets/images/alimentation2.png'),
      alimentationTypes:[
        'Viande à chaque repas',
        'Omnivore',
        'Sans boeuf',
        'Végétarien',
        'Végétalien'
      ]
    }
  }, 
  components:{
    AlimentationInput
  }
}
</script>
<style>
.alimentation-calculator-container{
  position: relative;
  background-color: white;
  padding: 40px;
  border-bottom-right-radius: 50px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
}
</style>
