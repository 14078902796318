<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="05" :question="question" :comment="comment"></QuestionTitle>
        <div class="container-70">
            <DetailDistanceCalculatorEvent :ponderationActive="false" :globalQuestions="globalQuestions" :transportsQuestions="transportsQuestions"></DetailDistanceCalculatorEvent>
            <router-link to="./question-6" class="btn-primary d-block w-100 mt-50px text-center">Suivant</router-link>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import DetailDistanceCalculatorEvent from '@/components/DetailDistanceCalculatorEvent.vue'
import AvancementLine from '@/components/AvancementLine.vue'
export default {
    data() {
        return {
            question:'Indiquez les distances allers-retours parcourues par toutes les personnes invitées à votre évènement. ',
            transportsQuestions: [
                {
                    label: 'Auto-solo',
                    icon: require('@/assets/images/transport_sous compacte.png'),

                },
                {
                    label: 'Covoiturage',
                    icon: require('@/assets/images/transport_compacte.png'),

                },
                {
                    label: 'Avion',
                    icon: require('@/assets/images/transport_avion.png'),

                },
                {
                    label: 'Transport en commun /<br/> interurbain',
                    icon: require('@/assets/images/transport_bus.png'),

                },
                {
                    label: 'Transport actif',
                    icon: require('@/assets/images/transport_velo.png'),
                },
                {
                    label: 'Camion',
                    icon: require('@/assets/images/camion.png'),
                }
            ]
        }
    },
    components: {
        QuestionTitle,
        DetailDistanceCalculatorEvent,
        AvancementLine,
    }
}
</script>