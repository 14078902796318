<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="04" :question="question" @activateModal="modalActive=true" :modalActivator="true" :modalActive="modalActive"></QuestionTitle>
        <div class="container-70">
            <LodgingCalculator v-model="results"></LodgingCalculator>
            <div  @click="saveAndContinue('touristLodging', JSON.stringify(results), './question-5')" class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine   percent="60%" class="mt-50px"></AvancementLine>
        </div>
        <ModalInfo @closeModal="modalActive=false" v-if="modalActive">
            <h3 class="modal-title">GES émis par vos lieux d’hébergement</h3>
            <p class="modal-text">Les GES émis par un bâtiment relèvent de la consommation énergétique nécessaire pour le chauffer. Plusieurs facteurs déterminent la quantité de GES émis, tels que la source d'énergie utilisée, les besoins et habitudes de consommation d'énergie, la performance énergétique du bâtiment, ainsi que sa superficie.</p>
        </ModalInfo>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import ModalInfo from '@/components/ModalInfo.vue'
import LodgingCalculator from '@/components/LodgingCalculator.vue'
import AvancementLine from '@/components/AvancementLine.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default{
    data(){
        return {
            results:null,
            question: 'Dans quel type d’hébergement séjournez-vous? ',
            modalActive:false
        }
    },
    components:{
        QuestionTitle,
        ModalInfo,
        LodgingCalculator,
        AvancementLine
    },
    mixins:[utils]

}
</script>