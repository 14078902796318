<template>
    <div class="body-results">
        <div>
            <h2 class="title-body-results">Votre voyage émet environ</h2>
            <div class="result-container">
                <div class="text-number-emission"><span class="number-emission">{{result}}</span> Tonnes de
                    CO<sub><small>2</small></sub> équivalent</div>
                <div class="price-emission">{{(result * 25).toFixed(2)}}</div>
            </div>
            <div class="mt-50px d-flex justify-center">
                <a class="btn-primary" :href="'https://buy.stripe.com/test_cN2eYo3axbw59TqaEE?__prefilled_amount='+ (result * 25).toFixed(2)*100" target="_blank">Faire un don pour compenser mes GES</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        result:Number
    }
}
</script>
<style>
.body-results {
    width: 60%;
    display: flex;
    justify-content: center;
}
.title-body-results {
    color: var(--text-color);
    text-align: center;
    font-family: var(--font);
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.result-container{
    display:flex;

}
.number-emission {
    color: var(--text-color);
    text-align: center;
    font-family: var(--font);
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
}

.text-number-emission {
    color: var(--text-color);
    font-family: var(--font);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.2px;
    border-radius: 0px 0px 50px 0px;
    border: 2px solid var(--text-color);
    background:  #CCEAED;
    padding: 12px 44px 12px 24px;
    z-index: 1;
}
.price-emission{
    border-radius: 0px 0px 50px 0px;
    background: var(--Secondary-Eau, #00B5C6);
    color: var(--Secondary-blanc, #FFF);
    text-align: center;
    font-family: var(--font);
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 12px 44px 12px 68px;
    transform: translateX(-42px);
   
}
</style>