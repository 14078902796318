<template>
    <div class="row space-around">
        <div class="col-45">
            <div @click="$emit('update:modelValue', button1)" class="btn-primary d-block w-100 text-center">
                <div v-if="button1.icon">
                    <img :src="button1.icon" />
                </div>
                <div :class="{boldText: bold}">
                    {{ button1.label }}
                </div>
            </div>
        </div>
        <div class="col-45">
            <div  @click="$emit('update:modelValue', button1)" class="btn-primary d-block w-100 text-center">
                <div v-if="button2.icon">
                    <img :src="button2.icon" />
                </div>
                <div :class="{boldText: bold}">
                    {{ button2.label }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        button1: Object,
        button2: Object,
        bold:{
            type:Boolean,
            default:false
        }
    }
}
</script>
<style>
.boldText{
    color: var(--text-color);
    font-family: var(--font);
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 33.2px; /* 103.75% */
}
</style>