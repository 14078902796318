<template>
    <div class="white-container-no-padding d-flex">
        <div>
            <img :src="hydroImg"/>
        </div>
        <div class="d-flex vertical-center justify-center w-100">
            <div class="btn-primary m-20px plr-50px">Oui</div>
            <div class="btn-primary m-20px plr-50px">Non</div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            hydroImg:require('@/assets/images/hydro.png')
        }
    }
}
</script>