import { createWebHistory, createRouter } from "vue-router";

import Home from "@/views/HomeQuestion.vue";
import WhoAreYou from "@/views/WhoAreYou.vue";
//Touriste
import TouristeChoixCalcul from "@/views/touriste/TouristeChoixCalcul.vue";
import Q1BothTourist from "@/views/touriste/Q1Both.vue";
import Q2BothTourist from "@/views/touriste/Q2Both.vue";
//Touriste simple
import Q3SimpleTourist from "@/views/touriste/simple/Q3Simple.vue";
import Q4SimpleTourist from "@/views/touriste/simple/Q4Simple.vue";
import Q5SimpleTourist from "@/views/touriste/simple/Q5Simple.vue";
//Touriste detail
import Q3DetailleTourist from "@/views/touriste/details/Q3Detaille.vue";
import Q4DetailleTourist from "@/views/touriste/details/Q4Detaille.vue";
import Q5DetailleTourist from "@/views/touriste/details/Q5Detaille.vue";
import Q6DetailleTourist from "@/views/touriste/details/Q6Detaille.vue";
//Évenement
import EvenementChoixCalcul from "@/views/evenement/EvenementChoixCalcul.vue";
//Évenement simple
import EvenementQ1Simple from "@/views/evenement/simple/Q1Simple.vue";
import EvenementQ2Simple from "@/views/evenement/simple/Q2Simple.vue";
//Évènement detaillee
import EvenementQ1Detaillee from "@/views/evenement/details/Q1Detaillee.vue";
import EvenementQ2Detaillee from "@/views/evenement/details/Q2Detaillee.vue";
import EvenementQ3Detaillee from "@/views/evenement/details/Q3Detaillee.vue";
import EvenementQ3DetailleeOptionA from "@/views/evenement/details/Q3DetailleeOptionA.vue";
import EvenementQ3DetailleeOptionB from "@/views/evenement/details/Q3DetailleeOptionB.vue";
import EvenementQ4Detaillee from "@/views/evenement/details/Q4Detaillee.vue";
import EvenementQ4DetailleeOptionA from "@/views/evenement/details/Q4DetailleeOptionA.vue";
import EvenementQ4DetailleeOptionB from "@/views/evenement/details/Q4DetailleeOptionB.vue";
import EvenementQ5Detaillee from "@/views/evenement/details/Q5Detaillee.vue";
import EvenementQ5DetailleeOptionA from "@/views/evenement/details/Q5DetailleeOptionA.vue";
import EvenementQ5DetailleeOptionB from "@/views/evenement/details/Q5DetailleeOptionB.vue";
import EvenementQ6Detaillee from "@/views/evenement/details/Q6Detaillee.vue";
import EvenementQ6DetailleeOptionA from "@/views/evenement/details/Q6DetailleeOptionA.vue";
import EvenementQ6DetailleeOptionB from "@/views/evenement/details/Q6DetailleeOptionB.vue";
import EvenementQ7Detaillee from "@/views/evenement/details/Q7Detaillee.vue";
import EvenementQ8Detaillee from "@/views/evenement/details/Q8Detaillee.vue";

//Result
import QuestionnaireResults from "@/views/QuestionnaireResults.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/qui-etes-vous",
    name: "WhoAreYou",
    component: WhoAreYou,
  },
  {
    path: "/touriste",
    children: [
      {
        path: "choix-calcul",
        component: TouristeChoixCalcul,
      },
      {
        path: "simple",
        children: [
          {
            path: "question-1",
            component: Q1BothTourist,
          },
          {
            path: "question-2",
            component: Q2BothTourist,
          },
          {
            path: "question-3",
            component: Q3SimpleTourist,
          },
          {
            path: "question-4",
            component: Q4SimpleTourist,
          },
          {
            path: "question-5",
            component: Q5SimpleTourist,
          },
        ],
      },
      {
        path: "detaille",
        children: [
          {
            path: "question-1",
            component: Q1BothTourist,
          },
          {
            path: "question-2",
            component: Q2BothTourist,
          },
          {
            path: "question-3",
            component: Q3DetailleTourist,
          },
          {
            path: "question-4",
            component: Q4DetailleTourist,
          },
          {
            path: "question-5",
            component: Q5DetailleTourist,
          },
          {
            path: "question-6",
            component: Q6DetailleTourist,
          },
        ],
      },
    ],
  },
  {
    path: "/evenement",
    children: [
      {
        path: "choix-calcul",
        component: EvenementChoixCalcul,
      },
      {
        path: "simple",
        children: [
          {
            path: "question-1",
            component: EvenementQ1Simple,
          },
          {
            path: "question-2",
            component: EvenementQ2Simple,
          },
        ],
      },
      {
        path: "detaille",
        children: [
          {
            path: "question-1",
            component: EvenementQ1Detaillee,
          },
          {
            path: "question-2",
            component: EvenementQ2Detaillee,
          },
          {
            path: "question-3",
            component: EvenementQ3Detaillee,
          },
          {
            path: "question-3-option-a",
            component: EvenementQ3DetailleeOptionA,
          },
          {
            path: "question-3-option-b",
            component: EvenementQ3DetailleeOptionB,
          },
          {
            path: "question-4",
            component: EvenementQ4Detaillee,
          },
          {
            path: "question-4-option-a",
            component: EvenementQ4DetailleeOptionA,
          },
          {
            path: "question-4-option-b",
            component: EvenementQ4DetailleeOptionB,
          },
          {
            path: "question-5",
            component: EvenementQ5Detaillee,
          },
          {
            path: "question-5-option-a",
            component: EvenementQ5DetailleeOptionA,
          },
          {
            path: "question-5-option-b",
            component: EvenementQ5DetailleeOptionB,
          },
          {
            path: "question-6",
            component: EvenementQ6Detaillee,
          },
          {
            path: "question-6-option-a",
            component: EvenementQ6DetailleeOptionA,
          },
          {
            path: "question-6-option-b",
            component: EvenementQ6DetailleeOptionB,
          },
          {
            path: "question-7",
            component: EvenementQ7Detaillee,
          },
          {
            path: "question-8",
            component: EvenementQ8Detaillee,
          },
        ],
      },
    ],
  },
  {
    path: "/resultats",
    name: "QuestionnaireResults",
    component: QuestionnaireResults,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
