<template>
    <div class="light-color-bg position-relative">
        <HeaderMain title="Qui êtes-vous?"></HeaderMain>
        <div class="container-70 mt-20px">
            <selection-box :values="values" v-model="result" @update:modelValue="saveAndContinue('who', JSON.stringify(result.label), result.value)"></selection-box>
        </div>
    </div>
</template>
<script>
import HeaderMain from '../components/HeaderMain.vue'
import SelectionBox from '../components/SelectionBox.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            values: [
                {
                    img: require('@/assets/images/citoyen.png'),
                    label: 'Un citoyen',
                    value: "/",
                    description:"Je réside à Trois-Rivières et je souhaite calculer les émissions de gaz à effet de serre (GES) de mon ménage."
                },
                {
                    img: require('@/assets/images/organisation.png'),
                    label: 'Une organisation',
                    value: "/"
                },
                {
                    img: require('@/assets/images/touriste.png'),
                    label: 'Un évènement',
                    value: "/evenement/choix-calcul",
                    description:"Je souhaite calculer les émissions de gaz à effet de serres (GES) de mon évènement."
                   
                },
                {
                    img: require('@/assets/images/evenement.png'),
                    label: 'Un touriste',
                    value: "/touriste/choix-calcul",
                    description:"Je visite Trois-Rivières et je souhaite calculer les émissions de gaz à effet de serres (GES) de mon séjour."
                }
            ]
        }
    },
    methods:{
        clearPreviousForm(){
            sessionStorage.clear()
        }
    }, 
    components: {
        HeaderMain,
        SelectionBox
    },
    mixins:[utils],
    mounted(){
        this.clearPreviousForm()
    }
}
</script>