<template>
    <div class="footer-results">
        <div class="footer-container">
            <img :src="iconTRDurable" class="icon-tr-durable"/>
            <div>
                <h3>Vous avez des questions? </h3>
                <p>
                    N’hésitez pas à contacter l’équipe de la Fondation Trois-Rivières durable pour vous aider: <a href="mailto:eclore@3Rdurable.org">eclore@3Rdurable.org</a> 
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            iconTRDurable:require('@/assets/images/logo_tr_durable.png'),
        }
    }
}
</script>
<style scoped>
.footer-results {
    background-color: white;
    width: 100%;
}

.footer-container {
    display: flex;
    align-items: center;
    width: 50%;
    margin-left: 10%;
    margin-right: auto;
}
.icon-tr-durable{
    height:109px;
}
.footer-results h3{
    color: #00B5C6;
    /* DESKTOP/H4 */
    font-family: var(--font);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.2px; /* 103.75% */
    margin-bottom: 0;
}
.footer-results p{
    color: var(--text-color);
    font-family: var(--font);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.2px;
    margin-top:5px;
}
</style>