<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="04" :question="question" :modalActivator="true" @activateModal="modalActive=true"></QuestionTitle>
        <div class="container-70 mt-20px">
            <SelectOptionBox :img="imgAlimentation" :options="options" v-model="result"></SelectOptionBox>
            <div  @click="saveAndContinue('touristAlimentation', JSON.stringify(result), './question-5')" class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine  class="mt-50px" percent="80%"></AvancementLine>
        </div>
        <ModalInfo @closeModal="modalActive=false" v-if="modalActive">
            <h3 class="modal-title">GES émis par votre alimentation</h3>
            <p class="modal-text">La production des protéines animales émet nettement plus de GES que celle des protéines végétales, notamment parce qu'elle nécessite plus de terres agricoles et plus d'eau. Parmi les protéines animales, les ruminants ont le plus  grand impact environnemental, car ils relâchent du méthane dans l'atmosphère lors de leur digestion, soit un gaz 28 fois plus néfaste que le CO2. Cette section permet d'estimer l'impact environnemental de vos habitudes alimentaires selon le type de protéine que vous privilégiez.</p>
        </ModalInfo>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import AvancementLine from '@/components/AvancementLine.vue'
import SelectOptionBox from '@/components/SelectOptionBox.vue'
import ModalInfo from '@/components/ModalInfo.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            modalActive:false,
            question:'Spécifiez le profil alimentaire moyen des voyageurs.',
            imgAlimentation:require('@/assets/images/alimentation.png'),
            options:[
                {
                    label:"Viande à tous les repas",
                    value:"Viande à tous les repas"
                },
                {
                    label:"Omnivore",
                    value:"Omnivore"
                },
                {
                    label:"Sans boeuf",
                    value:"Sans boeuf"
                },
                {
                    label:"Végétarien",
                    value:"Végétarien"
                },
                {
                    label:"Végétalien",
                    value:"Végétalien"
                }
            ]

        }
    },
    components: {
        QuestionTitle,
        AvancementLine,
        SelectOptionBox,
        ModalInfo
    },
    mixins:[utils]
}
</script>