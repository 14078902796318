<template>
    <div>
        <div class="d-flex align-end space-between">
            <div class="col-10">
                <img :src="energy.icon" class="w-100"/>
            </div>
            <div class="col-80">
                <label class="label-calculators ">
                    {{ energy.label }}
                </label>
                <div class="position-relative ">
                    <input type="number" class="calculator-input-square w-100" placeholder="Consommation"/>
                    <div class="unit">{{ energy.unit }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        energy: Object
    }
}
</script>
<style scoped>
.unit{
    position:absolute;
    top:50%;
    right: 10px;
    transform: translateY(-50%);
    color: var(--text-color);
    font-family: var(--font);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.2px; /* 141% */
}
</style>