<template>
    <div>
        <div class="question-container">
            <div class="question-number-container">
                <img :src="leave" />
                <div class="question-number">
                    {{ number }}
                </div>
            </div>
            <div class="question-text-container">
                <h1 class="question-text">
                    {{ question }}
                </h1>
                <p class="comment-text">{{ comment }}</p>
            </div>
            <div class="info-img" v-if="modalActivator">
                <img :src="infoImg" @click="$emit('activateModal')" v-if="!modalActive"/>
                <img :src="savoirPlusImg" @click="$emit('activateModal')" v-if="modalActive"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            leave: require("@/assets/images/feuille.png"),
            infoImg:require("@/assets/images/info.png"),
            savoirPlusImg:require("@/assets/images/ensavoirplus.png"),
        };
    },
    props: {
        number: String,
        question: String,
        comment: String,
        modalActivator:{type:Boolean, default:false},
        modalActive:{type:Boolean, default:false}
    },
};
</script>
<style scoped>
.question-container {
    position: relative;
    width: 92%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}
.question-number-container {
    position: relative;
}
.question-number {
    color: var(--text-color);
    font-family: var(--font);
    font-size: 25rem;
    font-style: normal;
    font-weight: 900;
    line-height: 1em;
    opacity: 0.25;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}
.question-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 25rem;
    margin-left:-3%;
}
.question-text {
    color: var(--text-color);
    font-family: var(--font);
    font-size: 5.3rem;
    line-height: 5.4rem;
    font-style: normal;
    font-weight: 600;
    z-index: 1000;
    margin-bottom: 0px;
}

.comment-text {
    margin-top: 0;
    color: var(--text-color);
    font-family: var(--font);
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 44.2px;
    z-index: 1000;
}
.info-img{
    cursor:pointer;
}
</style>
