<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="03" :question="question" @activateModal="modalActive=true" :modalActivator="true" :modalActive="modalActive"></QuestionTitle>
        <div class="container-70 mt-20px">
            <DetailDistanceCalculator class="mt-100px" v-model="results"></DetailDistanceCalculator>
            <div @click="saveAndContinue('touristDistanceKm', JSON.stringify(results), './question-4')" class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine  percent="60%" class="mt-50px"></AvancementLine>
        </div>
        <ModalInfo @closeModal="modalActive=false" v-if="modalActive">
            <h3 class="modal-title">GES émis par vos transports</h3>
            <p class="modal-text">Au Québec, c’est le secteur des transports qui émet le plus de GES et contribue le plus aux changements climatiques. Cette section permet d’estimer les GES émis par les transports effectués pour votre séjour à Trois-Rivières.</p>
        </ModalInfo>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import ModalInfo from '@/components/ModalInfo.vue'
import DetailDistanceCalculator from '@/components/DetailDistanceCalculator.vue'
import AvancementLine from '@/components/AvancementLine.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default{
    data(){
        return {
            results:null,
            question: ' Pour chacun des moyens de transport utilisés, veuillez indiquer les distances parcourues pour vous rendre à Trois-Rivières et pendant votre séjour.',
            modalActive:false
        }
    },
    components:{
        QuestionTitle,
        ModalInfo,
        DetailDistanceCalculator,
        AvancementLine
    },
    mixins:[utils]
}
</script>