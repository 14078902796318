<template>
  <div>
    <div class="label-destination mt-20px ">{{ label }}</div>
    <div class="position-relative">
      <input
      type="text"
      class="select-input"
      placeholder="Sélectionnez dans la liste"
      v-model="selectValueLabel"
      @click="selectActive = true"
    />
    <CustomIcon v-if="!selectActive" name="chevron-down" class="input-chevron"></CustomIcon>
    <CustomIcon v-if="selectActive" name="chevron-up" class="input-chevron" @click="selectActive=false"></CustomIcon>
    <div class="select-container" v-if="selectActive">
      <div
        class="select-options"
        v-for="(option, index) in options"
        :key="index"
        @click="selectOption(option)"
      >
        {{ option.label }}
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import CustomIcon from '@/components/CustomIcon.vue'
export default {
  data() {
    return {
        selectValueLabel:'',
        selectActive: false
    };
  },
  components:{
    CustomIcon
  },
  props: {
    label: String,
    options: Array,
  },
  methods:{
    selectOption(option){
        this.$emit('update:modelValue', option.value)
        this.selectActive=false
        this.selectValueLabel=option.label
    }
  }
};
</script>
<style>
.select-input{
  padding: 22px 35px;
  border: 3px solid #3B3B3C;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
  font-family: var(--font);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  width:100%;
  box-sizing: border-box;
}
.input-chevron{
  position:absolute;
  right: 15px;
  top:50%;
  transform: translateY(-50%);
}
.select-input::placeholder{
  color: #BAB1B1;
}
.select-container {
  border: 3px solid var(--Secondary-Noir, #3B3B3C);
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
  position:absolute;
  background-color:white;
  width:100%;
  box-sizing: border-box;
  padding: 22px 35px;
}
.select-options {
  color: var(--text-color);
  font-family: var(--font);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.2px; /* 127.692% */
  padding: 12px 8px;
}
.select-options:hover {
  background: #CCEAED;
  cursor: pointer;
}
.select-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.select-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.select-container::-webkit-scrollbar-thumb {
  background: var(--text-color);
}

/* Handle on hover */
.select-container::-webkit-scrollbar-thumb:hover {
  background: var(--text-color);
}
</style>
