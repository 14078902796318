<template>
  <div class="white-container">
    <div
      v-for="(activity, index) in activities"
      :key="index"
      class="d-flex vertical-center space-between mt-15px"
    >
      <div class="d-flex vertical-center">
        <div>
          <img :src="activity.icon" class="activity-img" />
        </div>
        <div>
          <h2>{{ activity.title }}</h2>
          <p class="long-text">{{ activity.description }}</p>
        </div>
      </div>
      <div class="position-relative">
        <input class="calculators-input-round" v-model="results[activity['title']]" @input="$emit('update:modelValue', results)"/>
        <div class="percent-activity">%</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      results:{

      },
      activities: [
        {
          title: "Activités peu émettrices",
          icon: require("@/assets/images/musee.png"),
          description:
            "Cinéma, plein air, musée, site historique, restaurant, centre d’amusement, etc.",
        },
        {
          title: "Activités moyennement émettrices",
          icon: require("@/assets/images/Concert.png"),
          description:
            "Centre aquatique, ski alpin, spa, tour guidé en autobus, festival et spectacle, etc.",
        },
        {
          title: "Activités fortement émettrices",
          icon: require("@/assets/images/karting.png"),
          description: "VTT, motoneige, karting, fête foraine, etc.",
        },
        {
          title: "Activités très fortement émettrices",
          icon: require("@/assets/images/parachute.png"),
          description: "Hélicoptère, avion, bateau, parachutisme, etc.",
        },
      ],
    };
  },
};
</script>
<style scoped>
h2 {
  color: var(--text-color);
  font-family: var(--font);
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 33.2px; /* 103.75% */
}
.activity-img {
  max-width: 191.5px;
  max-height: 191.503px;
}
.percent-activity{
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    right:40px;
    color: #BAB1B1;
    text-align: center;
    font-family: var(--font);
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
</style>
