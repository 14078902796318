<template>
    <div class="white-container">
        <div v-if="ponderationActive">
            <p class="long-text">Pour chaque type d’activités réalisées durant le voyage à Trois-Rivières, indiquez la
                pondération de participation. </p>
            <div class="d-flex vertical-center space-between" v-for="(globalQuestion, index) in globalQuestions"
                :key="index">
                <div class="col-10">
                    <img :src="globalQuestion.icon" class="w-100" />
                </div>
                <div class="col-40">
                    <div class="label-type">{{ globalQuestion.label }}</div>
                    <div class="long-text">{{ globalQuestion.description }}</div>
                </div>
                <div class="col-40 position-relative">
                    <input type="number" class="calculators-input-round w-100">
                    <div v-if="globalQuestion.unit" class="big-input-unit">{{ globalQuestion.unit }}</div>
                </div>
            </div>
        </div>
        <div>
            <h2 class="title-gen">Transports</h2>
            <p class="long-text" v-if="commentTransport">{{ commentTransport }}</p>
            <div class="row">
                <div v-for="(transportsQuestion, index) in transportsQuestions" :key="index" class="col-50">
                    <div class="d-flex space-between">
                        <div class="col-15">
                            <img :src="transportsQuestion.icon" class="w-100" />
                        </div>
                        <div class="col-80">
                            <label class="label-calculators" v-html="transportsQuestion.label"></label>
                            <div class="position-relative">
                                <input type="number" class="calculator-input-square w-100">
                                <div v-if="smallUnit" class="small-input-unit" v-html="smallUnit"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        ponderationActive: { type: Boolean, default: true },
        smallUnit: { type: String, default: '%' },
        globalQuestions: Array,
        transportsQuestions: Array,
        commentTransport: String
    }
}
</script>
<style scoped>
.label-type {
    color: var(--text-color);
    font-family: var(--font);
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 33.2px;
    /* 103.75% */
}

.big-input-unit {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    color: #BAB1B1;
    font-family: var(--font);
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.small-input-unit {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    color: var(--text-color);
    font-family: var(--font);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.2px;
    /* 141% */
}
</style>