<template>
    <div class="unit-selector">

    </div>
</template>
<script>
export default{
    props:{
        units:Array
    }
}
</script>
<style>
.unit-selector{
    
}
</style>