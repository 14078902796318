<template>
    <div class="white-container-no-padding d-flex">
        <div>
            <img :src="img"/>
        </div>
        <div class="d-flex vertical-center justify-center direction-column w-100">
            <div class="fake-input">
                <input/>
                <UnitSelector></UnitSelector>
            </div>
            <div class="label-calculators">Superficie de l’évènement</div>
        </div>
    </div>
</template>
<script>
import UnitSelector from '@/components/form/UnitSelector.vue'
export default {
    data(){
        return {
            img:require('@/assets/images/market.png')
        }
    },
    components: {
        UnitSelector
    }
}
</script>
<style>
.fake-input{
    display: flex;
    align-items: center;
    padding: 22px 35px;
    border: 3px solid var(--Secondary-Noir, #3B3B3C);   
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
}
</style>