<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="01" :question="question"></QuestionTitle>
        <div class="container-70">
            <NbCalculator :img="peopleImg" :listOfInputs="listOfInputs" v-model="result"></NbCalculator>
            <div @click="saveAndContinue('touristNbPerson', JSON.stringify(result), './question-2')" class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import AvancementLine from '@/components/AvancementLine.vue'
import NbCalculator from '@/components/NbCalculator.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            question:'Combien de personnes participent au voyage à Trois-Rivières?',
            peopleImg:require('@/assets/images/peoples.png'),
            listOfInputs:[
                {label:'Adultes', description:'16 ans et plus'},
                {label:'Enfants', description:'moins de 16 ans'}
            ]
        }
    },
    components: {
        QuestionTitle,
        NbCalculator,
        AvancementLine
    },
    mixins:[utils]
}
</script>