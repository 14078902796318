<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="05" :question="question" :modalActivator="true" @activateModal="modalActive=true"></QuestionTitle>
        <div class="container-70 mt-20px">
            <selection-box :values="values" v-model="result" @update:modelValue="saveAndContinue('touristActivity', JSON.stringify(result.label), '/resultats')"></selection-box>
            <AvancementLine  class="mt-50px" percent="100%"></AvancementLine>
        </div>
        <ModalInfo @closeModal="modalActive=false" v-if="modalActive">
            <h3 class="modal-title">GES émis par vos activités</h3>
            <p class="modal-text">Certaines activités ont une empreinte carbone plus élevée que d'autres. Par exemple, faire une randonnée dans un boisé est moins dommageable pour l'environnement que de s'y promener en VTT. Cette section permet d'évaluer les GES émis par les activités que vous réalisez durant votre séjour à Trois-Rivières.</p>
        </ModalInfo>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import AvancementLine from '@/components/AvancementLine.vue'
import ModalInfo from '@/components/ModalInfo.vue'
import SelectionBox from '@/components/SelectionBox.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            question:'Spécifiez le type d’activités réalisées durant le voyage à Trois-Rivières.',
            modalActive:false,
            values: [
                {
                    img: require('@/assets/images/musee.png'),
                    label: 'Activités peu émettrices',
                    description:'Cinéma, plein air, musée, site historique, restaurant, centre d’amusement, etc.',
                    value: "/",
                    imgWidth:'176px',
                    imgHeight:'176px'
                },
                {
                    img: require('@/assets/images/musee.png'),
                    label: 'Activités moyennement émettrices',
                    description:'Centre aquatique, ski alpin, spa, tour guidé en autobus, festival et spectacle, etc.',
                    value: "/",
                    imgWidth:'176px',
                    imgHeight:'176px'
                },
                {
                    img: require('@/assets/images/karting.png'),
                    label: 'Activités fortement émettrices',
                    description:'VTT, motoneige, karting, fête foraine, etc.',
                    value:'',
                    imgWidth:'176px',
                    imgHeight:'176px'
                },
                {
                    img: require('@/assets/images/parachute.png'),
                    label: 'Activités très fortement émettrices',
                    description:'Hélicoptère, avion, bateau, parachutisme, etc.',
                    value: "/",
                    imgWidth:'176px',
                    imgHeight:'176px'
                }
            ]
            
        }
    },
    components: {
        QuestionTitle,
        AvancementLine,
        SelectionBox,
        ModalInfo
    },
    mixins:[utils],
    methods:{
        saveAndNext(){
            this.$router.push('/resultats')
        }
    }
}
</script>