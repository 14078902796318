<template>
  <div class="distance-calculator-container">
    <div>
      <h2>Véhicules légers</h2>
      <div class="row">
          <div v-for="(vehicule, index) in vehicules" :key="index" class="col-50">
            <div>
              <DistanceCalculatorInput :vehicule="vehicule" v-model="results[vehicule.type]" @update:modelValue="$emit('update:modelValue', results)"></DistanceCalculatorInput>
            </div>
          </div>
      </div>
    </div>
    <div>
      <h2>Transports en commun</h2>
      <p class="long-text">Dans le cas où plus d’une personne participant au voyage utilise le transport collectif,
        il faut additionner
        les kilomètres de chaque passager. (exemple : 200km parcourrus et 3 personnes à bord = 600 km).</p>
        <div class="row">
          <div class="col-50"><DistanceCalculatorInput :vehicule="transportCommun" v-model="results['Autobus urbain ou interurbain']" @update:modelValue="$emit('update:modelValue', results)"></DistanceCalculatorInput></div>
          <div class="col-50"><DistanceCalculatorInput :vehicule="plane" :plane="true" v-model="results['Avion']" @update:modelValue="$emit('update:modelValue', results)"></DistanceCalculatorInput></div>
        </div>
    </div>
  </div>
</template>
<script>
import DistanceCalculatorInput from '@/components/form/DistanceCalculatorInput.vue'
export default {
  data() {
    return {
      results:{},
      vehicules: [
        {
          type: 'Sous-compacte',
          icon: require('@/assets/images/transport_sous compacte.png')
        },
        {
          type: 'Compacte',
          icon: require('@/assets/images/transport_compacte.png')
        },
        {
          type: 'Berline',
          icon: require('@/assets/images/transport_berline.png')
        },
        {
          type: 'VUS compact',
          icon: require('@/assets/images/transport_vus_compacte.png')
        },
        {
          type: 'VUS grand format, pick-up ou fourgonette',
          icon: require('@/assets/images/transport_grand-suv-pickup.png')
        },
        {
          type: 'Voiture hybride branchable',
          icon: require('@/assets/images/transport_hybride.png')
        },
        {
          type: 'Voiture électrique',
          icon: require('@/assets/images/transport_electrique.png')
        },
        {
          type: 'Moto',
          icon: require('@/assets/images/moto.png')
        }
      ],
      transportCommun: {
          type: 'Autobus urbain ou interurbain',
          icon: require('@/assets/images/transport_bus.png')
      },
      plane: {
          type: 'Avion',
          icon: require('@/assets/images/transport_avion.png')
      }
    }
  },
  components: {
    DistanceCalculatorInput
  }
}
</script>
<style scoped>
.distance-calculator-container {
  position: relative;
  background-color: white;
  border-bottom-right-radius: 50px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
  padding: 36px 56px;
}

.distance-calculator-container h2 {
  color: var(--text-color);
  font-family: var(--font);
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

</style>