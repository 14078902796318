<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="03" :question="question" @activateModal="modalActive=true" :modalActivator="true" :modalActive="modalActive"></QuestionTitle>
        <div class="container-70 mt-20px">
            <InputBox :img="imgVoiture" :inputInfo="inputInfo" v-model="result"></InputBox>
            <div @click="saveAndContinue('touristDistanceKm', JSON.stringify(result), './question-4')" class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine  class="mt-50px" percent="60%"></AvancementLine>
        </div>
        <ModalInfo @closeModal="modalActive=false" v-if="modalActive">
            <h3 class="modal-title">GES émis par vos transports</h3>
            <p class="modal-text">Au Québec, c’est le secteur des transports qui émet le plus de GES et contribue le plus aux changements climatiques. Cette section permet d’estimer les GES émis par les transports effectués pour votre séjour à Trois-Rivières.</p>
        </ModalInfo>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import InputBox from '@/components/InputBox.vue'
import AvancementLine from '@/components/AvancementLine.vue'
import ModalInfo from '@/components/ModalInfo.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            imgVoiture:require('@/assets/images/voiture.png'),
            question:'Quelle distance avez-vous parcouru?',
            inputInfo:{
                label:'Distance totale en Kilomètres'
            },
            modalActive:false
        }
    },
    components: {
        QuestionTitle,
        InputBox,
        AvancementLine,
        ModalInfo
    },
    mixins:[utils]
}
</script>