<template>
  <div class="dark-color-bg">
    <div
      class="bandeau"
      :style="{
        backgroundImage: 'url(' + require('@/assets/images/bandeau.png') + ')',
      }"
    ></div>
    <div class="container h-70vh">
      <div class="row">
        <div class="col-30 column-center position-relative">
          <img :src="ecloreLogoWhite" class="ecloreLogo" />
          <img :src="worldImg" class="worldLogo" />
        </div>
        <div class="col-60 pl-5per mt-50px">
          <div>
            <h1>
              Bienvenue sur le calculateur<br />
              carbone d’Éclore fonds <br />environnement Trois-Rivières!
            </h1>
            <p class="paragraph">
              Il s’agit d’un outil simple pour vous aider à estimer vos
              émissions de gaz à effet de serre. En plus de vous sensibiliser à
              votre empreinte carbone, vous aurez la possibilité à la fin du
              parcours de faire un don qui permettra l’achat de crédits carbone
              certifiés VCS auprès de notre fournisseur. Une partie des profits
              de la vente de ces crédits sera remis à Éclore pour financer des
              projets en développement durable sur le territoire de la Ville de
              Trois-Rivières.
            </p>
            <h2 class="thx">
              Merci de faire ce geste et d’encourager cette initiative locale!
            </h2>
            <div class="mt-20px">
              <router-link class="btn-primary" to="/qui-etes-vous"
                >Calculer son empreinte carbone</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import worldImg from "../assets/images/world.png";
import ecloreLogoWhite from "../assets/images/eclore_logo_white.png";
export default {
  data() {
    return {
      worldImg,
      ecloreLogoWhite,
    };
  }
};
</script>
<style scoped>
.bandeau {
  height: 30vh;
  width: 100%;
  background-position: top;
  background-size: cover;
}
.ecloreLogo {
  width: 90%;
  position:absolute;
  top:-140px;
}
.worldLogo {
  max-width: 100%;
  position:absolute;
  top:200px
}
.thx {
  font-family: var(--font);
  color: var(--text-color);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.2px;
}
</style>
