<template>
    <div>
        <div class="d-flex space-between mb-40px">
            <div class="advancement-text">Avancement</div>
            <div class="advancement-text">{{percent}}</div>
        </div>
        <div class="avancement-line">
            <div class="advancement-indicator" :style="{'width':percent}"></div>
        </div>
        <div class="d-flex d-flex-end">
            <div @click="$router.back()" class="previous">Revenir à la question précédente</div>
        </div>
        <div class="pt-40px"></div>
    </div>
</template>
<script>
export default{
    props:{
        percent:String
    }
}
</script>
<style>
.advancement-indicator{
    height:8px;
    background-color:#00B5C6;
    border-radius:20px;
}
.advancement-text{
    color:var(--text-color);
    font-family: var(--font);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28.2px; /* 141% */
}
.avancement-line{
    width:100%;
    height:8px;
    background: #96D3DD;
    border-radius:20px;
}
.previous{
    color:#00B5C6;
    text-align: right;
    /* DESKTOP/Details */
    font-family: var(--font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
}
</style>