<template>
    <div v-if="lodging">
        <div>
            <label class="label-calculators">{{ lodging.type }}</label>
            <div class="d-flex vertical-center">
                <div class="col-20" >
                    <img :src="lodging.icon" class="w-100"/>
                </div>
                <div class="position-relative col-38">
                    <input type="number" class="lodging-calculator-input w-100" placeholder="Temps passé dans l’hébergement" v-model="results['temps']" @input="$emit('update:modelValue', results)"/>
                    <div class="percent">%</div>
                </div>
                <div class="position-relative col-38" v-if="!lodging.onlyOne">
                    <input type="number" class="lodging-calculator-input w-100" placeholder="Nombre de chambres occupées"  v-model="results['nbChambre']" @input="$emit('update:modelValue', results)"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            results:{
                temps:null,
                nbChambre:null
            },
        }
    },
    props: {
        lodging: Object
    }
}
</script>
<style scoped>
.col-38{
    width: 38%;
    margin-right: 2%;;
}
.lodging-calculator-input {
    display: flex;
    height: 57px;
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-right: 10px;
    border: 2px solid var(--Secondary-Noir, #3B3B3C);
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
    text-align: start;
    box-sizing:border-box;
}
.lodging-calculator-input::placeholder{
    color: #bab1b1;
    font-family: var(--font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.2px; /* 235% */
}
.percent{
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-color);
    font-family: var(--font);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.2px;
    /* 141% */
}
</style>