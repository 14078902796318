<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="02" :question="question"></QuestionTitle>
        <div class="container-70">
            <NbCalculator :img="imgExploratrice" :listOfInputs="listOfInputs" v-model="result"></NbCalculator>
            <div @click="saveAndContinue('touristNbJours', JSON.stringify(result), './question-3')" class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine  class="mt-50px" percent="40%"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import NbCalculator from '@/components/NbCalculator.vue'
import AvancementLine from '@/components/AvancementLine.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            imgExploratrice:require('@/assets/images/exploratrice.png'),
            question:'Quelle est la durée du voyage?',
            listOfInputs:[
                {label:'Jours', description:''},
            ]
        }
    },
    components: {
        QuestionTitle,
        AvancementLine,
        NbCalculator
    },
    mixins:[utils]
}
</script>