<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="04" :question="question"></QuestionTitle>
        <div class="container-70">
            <DoubleButtonChoice :button1="button1" :button2="button2" class="mt-100px" v-model="result"  @update:modelValue="saveAndContinue('evenementDistancePartnerChoice', JSON.stringify(result.label), result.link)"></DoubleButtonChoice>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import DoubleButtonChoice from '@/components/DoubleButtonChoice.vue'
import AvancementLine from '@/components/AvancementLine.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            question:'Par quel moyen souhaitez-vous calculer les GES émis par les déplacements des organisateurs(trices), partenaires, fournisseurs et bénévoles de votre évènement?',
            modalActive:false,
            button1:{
                link:'./question-4-option-a',
                label:'Avec la distance moyenne parcourue par personne'
            },
            button2:{
                link:'./question-4-option-b',
                label:'Avec les distances réelles parcourues par toutes les personnes'
            }
        }
    },
    components: {
        QuestionTitle,
        AvancementLine,
        DoubleButtonChoice
    },
    mixins:[utils]
}
</script>