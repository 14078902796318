<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="01" :question="question"></QuestionTitle>
        <div class="container-70">
            <NbCalculator :img="peopleImg" :listOfInputs="listOfInputs" v-model="result"></NbCalculator>
            <div @click="saveAndContinue('evenementDuree', JSON.stringify(result), './question-2')"  class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import AvancementLine from '@/components/AvancementLine.vue'
import NbCalculator from '@/components/NbCalculator.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            question:'Quelle est la durée de votre évènement?',
            peopleImg:require('@/assets/images/gettogether.png'),
            listOfInputs:[
                {label:'Jours'}
            ]
        }
    },
    components: {
        QuestionTitle,
        NbCalculator,
        AvancementLine
    },
    mixins:[utils]
}
</script>