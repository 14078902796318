<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="03" :question="question"></QuestionTitle>
        <div class="container-70">
            <DetailDistanceCalculatorEvent :globalQuestions="globalQuestions" :commentTransport='commentTransport' :transportsQuestions="transportsQuestions"></DetailDistanceCalculatorEvent>
            <router-link to="./question-4" class="btn-primary d-block w-100 mt-50px text-center">Suivant</router-link>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import DetailDistanceCalculatorEvent from '@/components/DetailDistanceCalculatorEvent.vue'
import AvancementLine from '@/components/AvancementLine.vue'
export default {
    data() {
        return {
            question:'Remplissez le tableau suivant afin d’estimer les déplacements allers-retours de toutes les personnes du public qui ont assisté à votre évènement.',
            globalQuestions: [
                {
                    label: 'Nombre de personnes',
                    icon: require('@/assets/images/party2.png'),
                    description: "participant(e)s, employé(e)s, artistes, etc."               
                },
                {
                    label: 'Distance moyenne',
                    icon: require('@/assets/images/voiture_simple.png'),
                    description: "En aller-retour parcouru par personne",
                    unit:'Km'
                }

            ],
            commentTransport:'Pondération des moyens de transports utilisés par toutes les personnes qui ont assisté à l’évènement.',
            transportsQuestions: [
                {
                    label: 'Auto-solo',
                    icon: require('@/assets/images/transport_sous compacte.png'),

                },
                {
                    label: 'Covoiturage',
                    icon: require('@/assets/images/transport_compacte.png'),

                },
                {
                    label: 'Avion',
                    icon: require('@/assets/images/transport_avion.png'),

                },
                {
                    label: 'Transport en commun /<br/> interurbain',
                    icon: require('@/assets/images/transport_bus.png'),

                },
                {
                    label: 'Transport actif',
                    icon: require('@/assets/images/transport_velo.png'),
                },
                {
                    label: 'Camion',
                    icon: require('@/assets/images/camion.png'),
                }
            ]
        }
    },
    components: {
        QuestionTitle,
        DetailDistanceCalculatorEvent,
        AvancementLine,
    }
}
</script>