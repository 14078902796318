<template>
    <div class="headerMain">
        <img :src="leaveImg" alt="" class="leaveImg">
        <div class="text-container-header">
            <h2 class="headerMain-title">{{ title }}</h2>
            <p class="headerMain-description">{{description}}</p>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            leaveImg:require('@/assets/images/feuille.png'),
        }
    },
    props:{
        title:String,
        description:{Type:String, Default:null}
    }
}
</script>
<style scoped>
.headerMain{
    width:90%;
    margin-left:auto;
    margin-right:auto;
    position:relative;
}
.headerMain-title{
    color: var(--text-color);
    font-family: var(--font);
    font-size: 92px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin:0;
}
.text-container-header{
    position:absolute;
    top:22%;
    left:110px;
}
.headerMain-description{
    color: var(--text-color);
    font-family: var(--font);
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.2px; /* 127.692% */
    margin-top:0px;
}
</style>