<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="02" :question="question"></QuestionTitle>
        <div class="container-70">
            <SelectOptionBox :img="imgPortee" :options="options" v-model="result"></SelectOptionBox>
            <div @click="saveAndContinue('evenementPortee', JSON.stringify(result), '/resultats')" class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import SelectOptionBox from '@/components/SelectOptionBox.vue'
import AvancementLine from '@/components/AvancementLine.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            question:'Quelle est la portée de votre évènement?',
            imgPortee:require('@/assets/images/jambee.png'),
            options:[
                {
                    label:"Locale (15 km)",
                    value:"Locale (15 km)"
                },
                {
                    label:"Proximité (40 km)",
                    value:"Proximité (40 km)"
                },
                {
                    label:"Régionale (150 km)",
                    value:"Régionale (150 km)"
                },
                {
                    label:"Nationale (1 500 km)",
                    value:"Nationale (1 500 km)"
                },
                {
                    label:"Internationale (5 000 km)",
                    value:"Internationale (5 000 km)"
                },
                {
                    label:"Internationale + (10 000 km)",
                    value:"Internationale + (10 000 km)"
                }
            ]
        }
    },
    components: {
        QuestionTitle,
        SelectOptionBox,
        AvancementLine
    },
    mixins:[utils]
}
</script>