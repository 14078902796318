<template>
  <router-view></router-view>
</template>
<style>
@import url('./assets/css/style.css');
</style>
<script>
export default {
  name: 'App'
}
</script>