<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="06" :question="question" :comment="comment" @activateModal="modalActive=true" :modalActivator="true" :modalActive="modalActive"></QuestionTitle>
        <div class="container-70">
            <DoubleButtonChoice :button1="button1" :button2="button2" class="mt-100px" v-model="result" @update:modelValue="saveAndContinue('evenementConsommationEnergetique', JSON.stringify(result.label), result.link)"></DoubleButtonChoice>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
        <ModalInfo @closeModal="modalActive=false" v-if="modalActive">
            <h3 class="modal-title">GES émis par la consommation énergétique de votre évènement</h3>
            <p class="modal-text">Plusieurs facteurs déterminent la quantité de GES émis par la consommation d'énergie, tels que la source d'énergie utilisée, les besoins et habitudes de consommation d'énergie, ainsi que la superficie à fournir en énergie.</p>
        </ModalInfo>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import DoubleButtonChoice from '@/components/DoubleButtonChoice.vue'
import AvancementLine from '@/components/AvancementLine.vue'
import ModalInfo from '@/components/ModalInfo.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            question:'Avez-vous les données de consommation énergétique de votre évènement?',
            comment:'En kWh, m3 de gaz naturel, litres de propane, de mazout, d’essence ou de diesel.',
            modalActive:false,
            button1:{
                link:'./question-6-option-a',
                label:'Oui'
            },
            button2:{
                link:'./question-6-option-b',
                label:'Non'
            }
        }
    },
    components: {
        QuestionTitle,
        AvancementLine,
        DoubleButtonChoice,
        ModalInfo
    },
    mixins:[utils]
}
</script>