<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="06" :question="question"></QuestionTitle>
        <div class="container-70 mt-20px">
            <HydroOnly></HydroOnly>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import AvancementLine from '@/components/AvancementLine.vue'
import HydroOnly from '@/components/HydroOnly.vue'
export default {
    data() {
        return {
            question:'Est-ce que votre évènement utilise uniquement l’hydroélectricité comme source d’énergie?',
        }
    },
    components: {
        QuestionTitle,
        HydroOnly,
        AvancementLine
    }
}
</script>