<template>
    <div class="d-flex d-flex-end vertical-center mt-35px">
        <label class="alimentation-label">{{ label }}</label>
        <div class="position-relative">
            <input type="number" class="alimentation-input" v-model="result" @input="$emit('update:modelValue', result) "/>
            <div class="percent-alimentation">%</div>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return {
            result:null
        }
    },
    props:{
        label:String
    }
}
</script>
<style>
.percent-alimentation{
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    right:40px;
    color: #BAB1B1;
    text-align: center;
    font-family: var(--font);
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.alimentation-label{
    color: var(--text-color);
    text-align: right;
    font-family: var(--font);
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 33.2px; /* 103.75% */
    padding-right: 25px;
}
.alimentation-input{
    font-family: var(--font);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    padding: 22px 36px;
    border-radius: 0px 0px 50px 0px;
    border: 3px solid var(--Secondary-Noir, #3B3B3C);
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
}
/* Chrome, Safari, Edge, Opera */
input.alimentation-input::-webkit-outer-spin-button,
input.alimentation-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input.alimentation-input[type=number] {
  -moz-appearance: textfield;
}
</style>