<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="06" :question="question" :comment="comment"></QuestionTitle>
        <div class="container-70 mt-20px">
            <ConsommationEnergetique></ConsommationEnergetique>
            <router-link to="./question-7" class="btn-primary d-block w-100 mt-50px text-center">Suivant</router-link>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import AvancementLine from '@/components/AvancementLine.vue'
import ConsommationEnergetique from '@/components/ConsommationEnergetique.vue'
export default {
    data() {
        return {
            question:'Par quel moyen souhaitez-vous calculer les GES émis par les déplacements des conférencier(ère)s et artistes de votre évènement?',
            comment:'Ces informations sont facilement retraçables sur les factures de vos fournisseurs.'
        }
    },
    components: {
        QuestionTitle,
        AvancementLine,
        ConsommationEnergetique
    }
}
</script>