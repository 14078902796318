<template>
  <div>
    <div class="row " v-for="n in numberRow" :key="n" :class="{'mt-20px':n>1, 'space-between':!around, 'space-around':around}">
      <div v-for="(value, key) in getValuesForThisRow(n)" :key="key">
        <div class="selection-box" @click="$emit('update:modelValue', value)">
          <div v-if="value.imgHeight && value.imgWidth">
            <img :src="value.img"  :style="{width:value.imgWidth, height:value.imgHeight}"/>
          </div>
          <div v-else>
            <img :src="value.img" />
          </div>
          {{ value.label }}
          <div v-if="value.description" class="description">{{value.description}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    getValuesForThisRow(n) {
    let filterValue=[]
      this.values.forEach((value, key) => {
        if (
          key < (this.values.length / this.numberRow) * n &&
          key >= (this.values.length / this.numberRow) * (n - 1)
        ) {
            filterValue.push(value)
        }
      });
      return filterValue
    },
  },
  props: {
    values: Array,
    numberRow: { type: Number, default: 1 },
    around:{type:Boolean, default:false}
  },
};
</script>
<style scoped>
.row > * { flex: 1; }
.selection-box {
  background-color: white;
  padding: 12px 33px 12px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 50px 0px;
  color: var(--text-color);
  text-align: center;
  font-family: var(--font);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.2px; /* 103.75% */
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 10px;
  margin-right: 10px;
}
.selection-box:hover {
  transform: scale(1.01);
  cursor: pointer;
  background-color: #96D3DD;
  color:#00B5C6;
}
.selection-box:hover .description{
  display: block;
}
.selection-box:hover img{
  transform: scale(0.6);
}
.description{
  color: var(--text-color);
  /* DESKTOP/H6 */
  font-family: var(--font);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.2px; /* 141% */
  display: none;
}
</style>
