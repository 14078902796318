<template>
  <div class="lodging-calculator-container">
    <div>
      <p class="long-text">Indiquez les proportions pour chaque type d'hébergement ainsi que le nombre de chambres que
        vous avez occupées. Par exemple, pour un séjour de deux nuits avec une en camping et une dans une chambre
        d'hôtel, indiquez 50 % camping et 50 % hôtel avec 1 chambre.</p>
      <div class="row">
        <div class="col-50">
          <div v-for="(lodging, index) in lodgings" :key="index">
            <div v-if="index < lodgings.length / 2">
              <LodgingCalculatorInput :lodging="lodging" v-model="results[lodging.type]" @update:modelValue="$emit('update:modelValue', results)"></LodgingCalculatorInput>
            </div>
          </div>
        </div>
        <div class="col-50">
          <div v-for="(lodging, index) in lodgings" :key="index">
            <div v-if="index >= lodgings.length / 2">
              <LodgingCalculatorInput :lodging="lodging"></LodgingCalculatorInput>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LodgingCalculatorInput from '@/components/form/LodgingCalculatorInput.vue'
export default {
  data() {
    return {
      results:{

      },
      lodgings: [
        {
          type: 'Hôtel ou motel',
          icon: require('@/assets/images/hebergement_hotel.png')
        },
        {
          type: 'Chalet',
          icon: require('@/assets/images/hebergement_chalet.png')
        },
        {
          type: 'Camping',
          icon: require('@/assets/images/hebergement_camping.png'),
          onlyOne:true
        },
        {
          type: 'Appartement',
          icon: require('@/assets/images/hebergement_appartement.png')
        },
        {
          type: 'Auberge',
          icon: require('@/assets/images/hebergement_auberge.png')
        },
        {
          type: 'Maison',
          icon: require('@/assets/images/hebergement_maison.png')
        }
      ]
    }
  },
  components: {
    LodgingCalculatorInput
  }
}
</script>
<style scoped>
.lodging-calculator-container {
  position: relative;
  background-color: white;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom-right-radius: 50px;
  box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
}
</style>