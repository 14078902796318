<template>
    <div class="input-box-container mt-10px">
      <div class="input-box-img"><img :src="img" /></div>
      <div class="d-flex vertical-center d-flex-end p-60px">
        <div class="input-container">
            <input type="number" class="input-box" @input="$emit('update:modelValue', result)" v-model="result"/>
            <div class="input-box-label" :style="{'text-align':textAlign}">{{inputInfo.label}}</div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        result:null
      };
    },
    props: {
      img: String,
      inputInfo:Object,
      textAlign:{type:String, default:'center'}
    }
  };
  </script>
  <style scoped>
  .input-box-container {
    position: relative;
    background-color: white;
    display: flex;
    justify-content: space-between;
    border-bottom-right-radius: 50px;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
  }
  .input-box-input {
    width: 75px;
    height: 35px;
    padding: 22px 35px 22px 35px;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
    border: 3px solid var(--text-color);
    font-size: 42px;
    font-family: var(--font);
    text-align: center;
  }
  .input-box{
    border: 3px solid var(--Secondary-Noir, #3B3B3C);       
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
    padding: 22px 35px;
    color: var(--text-color);
    font-family: var(--font);
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align:center;
  }
  .input-container{
    display:flex;
    flex-direction: column;
  }
  .input-box-label{
    color: var(--text-color);
    font-family: var(--font);
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  </style>
  