<template>
    <div>
        <div v-for="(description, index) in descriptions" :key="index">
            <div class="choice-information" :class="{'mt-50px': index>0}">
                <div><img :src="smallLeave"/></div><div class="ml-20px">{{ description }}</div>
            </div>
        </div>
        <div class="choice-buttons">
            <div :to="simpleLink" class="btn-primary mlr-20px" @click="setAndGoTo('simple', simpleLink)">Calcul simplifié</div>
            <div :to="detailLink" class="btn-primary mlr-20px" @click="setAndGoTo('detaillee', detailLink)">Calcul détaillé</div>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return {
            smallLeave: require('@/assets/images/feuille_small.png'),
        }
    },
    props:{
        descriptions:Array,
        simpleLink:String,
        detailLink:String
    },
    methods:{
        setAndGoTo(value, url){
            sessionStorage.setItem('formType', value);
            this.$router.push(url);
        }
    }
}
</script>
<style>
.choice-information{
    display:flex;
    color: var(--text-color);
    font-family: var(--font);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.2px; /* 103.75% */
}
.choice-buttons{
    display:flex;
    justify-content: center;
    margin-top:75px;
}
</style>