<template>
    <div class="header-results">
        <div class="leaveImgContainer">
            <img :src="eclore" alt="" class="leaveImg">
        </div>
        <div class="header-text-container">
            <div>
                <h1 class="results-title">Vos Résultats</h1>
            </div>
            <div>
                <p class="results-description">Éclore fonds environnement Trois-Rivières vous remercient d’avoir
                    calculer l’empreinte carbone de votre séjour.</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            eclore: require('@/assets/images/eclore_big_logo.png'),
        }
    }
}
</script>
<style scoped>
.header-results {
    width: 60%;
    margin-left: 5%;
    margin-right: auto;
    position: relative;
    display: flex;
}

.header-text-container {
    margin-left: -4%;
}

.leaveImgContainer {
    max-width: 297px;
    max-height: 382.194px;
}

.leaveImg {
    max-width: 100%;
    max-height: 100%;
}

.results-title {
    color: var(--text-color);
    /* DESKTOP/Tittle-question */
    font-family: var(--font);
    font-size: 92px;
    font-style: normal;
    font-weight: 600;
    line-height: 1em;
    margin-top: 130px;
    margin-bottom: 0 !important;
}

.results-description {
    color: var(--text-color);
    /* DESKTOP/H5 */
    font-family: var(--font);
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 33.2px;
    /* 127.692% */
    margin-top: 0px;
}
</style>