<template>
    <div class="light-color-bg position-relative">
        <HeaderResults></HeaderResults>
        <BodyResults class="mt-40px" :result="result"></BodyResults>
        <FooterResults class="footer"></FooterResults>
        <div class="results-right-container" :style="{ backgroundImage: 'url(' + bgResultat + ')' }">
        </div>
    </div>
</template>
<script>
import HeaderResults from '@/components/results/HeaderResults'
import BodyResults from '@/components/results/BodyResults.vue'
import FooterResults from '@/components/results/FooterResults'
export default {
    data() {
        return {
            result: null,
            bgResultat: require('@/assets/images/resultat-ecran_bleu.png'),
            calculatorData: {
                provenanceVoyageur: [
                    { type: 'Locale (15km)', factor: 22.8128111363636, unite: 'FE (kgCO2)' },
                    { type: 'Proximité (50km)', factor: 54.0256222727273, unite: 'FE (kgCO2)' },
                    { type: 'Régionale (250km)', factor: 171.051244545455, unite: 'FE (kgCO2)' },
                    { type: 'Provinciale (500km)', factor: 342.102489090909, unite: 'FE (kgCO2)' },
                    { type: 'Nationale (1500km)', factor: 828.153733636364, unite: 'FE (kgCO2)' },
                    { type: 'Internationale (5000km)', factor: 2364.20497818182, unite: 'FE (kgCO2)' },
                    { type: 'Internationale+ (10000km)', factor: 4530.25622272727, unite: 'FE (kgCO2)' },
                    { type: 'Personnalisée', factor: 0, unite: 'FE (kgCO2)' }
                ],
                transportVoyage: [
                    { type: 'Métro', factor: 0.00007, unite: 'kg/passager/km' },
                    { type: 'Marche-vélo', factor: 0, unite: 'kg/km' },
                    { type: 'Bus urbain et interurbain', factor: 0.06, unite: 'kg/passager/km' },
                    { type: 'Train', factor: 0.11, unite: 'kg/passager/km' },
                    { type: 'Véhicule léger (voiture)', factor: 0.21, unite: 'kg/km' },
                    { type: 'Camion léger (VUS, mini-fourgonnette, camionnette)', factor: 0.32, unite: 'kg/km' },
                    { type: 'Voiture 100% électrique', factor: 0.00034, unite: 'kg/km' },
                    { type: 'Voiture Hybride', factor: 0.16, unite: 'kg/km' },
                    { type: 'Moto', factor: 0.13, unite: 'kg/km' },
                    { type: 'Avion (public)', factor: 0.12, unite: 'kg/passager/km' },
                    { type: 'Hélicoptère (privé)', factor: 0.52, unite: 'kg/km' },
                    { type: 'Prix moyen - avion', factor: 0.166, unite: '$/km' },
                    { type: 'Prix moyen - train & autobus', factor: 0.204, unite: '$/km' }
                ],
                batiment: [
                    { type: 'Résidentiel électrique', factor: 0.15, unite: 'kg CO2eq/m2' },
                    { type: 'Énergie - Résidentiel moyen', factor: 9.33952999978999, unite: 'kg CO2 eq m2' },
                    { type: 'Construction - Résidentiel moyen', factor: 8.5, unite: 'kg CO2 eq/m2/an' },
                    { type: 'Commercial - électrique exclusivement', factor: 0, unite: 'kg CO2eq/m2' },
                    { type: 'Commerce de gros', factor: 32.0806, unite: 'kg CO2eq/m2' },
                    { type: 'Commerce de détail', factor: 34.3666, unite: 'kg CO2eq/m2' },
                    { type: 'Transport et entreposage', factor: 27.9975, unite: 'kg CO2eq/m2' },
                    { type: 'Industrie information et culturelle', factor: 47.5266, unite: 'kg CO2eq/m2' },
                    { type: 'Bureaux', factor: 19.9526, unite: 'kg CO2eq/m2' },
                    { type: 'Établissement enseignement', factor: 35.1891, unite: 'kg CO2eq/m2' },
                    { type: 'Santé et assistance sociale', factor: 69.2832, unite: 'kg CO2eq/m2' },
                    { type: 'Arts, spectacles et loisirs', factor: 40.0433, unite: 'kg CO2eq/m2' },
                    { type: 'Restauration', factor: 57.1273, unite: 'kg CO2eq/m2' },
                    { type: 'Autres services', factor: 30.8334, unite: 'kg CO2eq/m2' },
                    { type: 'Construction - commercial moyen', factor: 8.5, unite: 'kg CO2 eq/m2/an' }
                ],
                alimentation: [
                    { type: 'Amateur de viande', factor: 3300, unite: 'kg CO2/personne' },
                    { type: 'Omnivore', factor: 2500, unite: 'kg CO2/personne' },
                    { type: 'Sans boeuf', factor: 1900, unite: 'kg CO2/personne' },
                    { type: 'Végétarien', factor: 1700, unite: 'kg CO2/personne' },
                    { type: 'Végane', factor: 1500, unite: 'kg CO2/personne' },
                ],
                matiereResiduelleTaux: [
                    { type: 'Enfouissement', factor: 1.67, unite: 'kg CO2eq / kg' },
                    { type: 'Recyclage', factor: 0.01, unite: 'kg CO2eq / kg' },
                    { type: 'Compostage', factor: 0.03, unite: 'kg CO2eq / kg' }
                ],
                matiereResiduelleQuantite: [
                    { type: 'Déchets domestique', factor: 229, unite: 'kg/habitant' },
                    { type: 'Déchets ICI', factor: 226, unite: 'kg/habitant' },
                    { type: 'Déchets CRD', factor: 169, unite: 'kg/habitant' },
                    { type: 'Déchets totaux', factor: 716, unite: 'kg/habitant' },
                ],
                carburant: [
                    { type: 'Essence (kgCO2/L)', factor: 2.31675, prix_moyen_l: '1,801' },
                    { type: 'Diesel (kgCO2/L)', factor: 2.70665, prix_moyen: '2,1035' },
                    { type: 'Propane automobile (kgCO2/L)', factor: 1.54034, prix_moyen_l: '0,9631' },
                    { type: 'Électricité (kgCO2/kWh)', factor: 0.0006, prix_moyen_l: '' },
                    { type: 'Gaz naturel (kgCO2/m3)', factor: 1.897311, prix_moyen_l: '' },
                    { type: 'Propane (kgCO2/L)', factor: 1.547532, prix_moyen_l: '' },
                    { type: 'Mazout (kgCO2/L)', factor: 2.7841425, prix_moyen_l: '' },
                    { type: 'Diesel (kgCO2/L)', factor: 2.3157, prix_moyen_l: '' },
                ],
                activite: [
                    { type: 'Activités peu émettrices', factor: 1, unite: 'kg CO2eq/personne/jour' },
                    { type: 'Activités moyennement émettrices', factor: 3, unite: 'kg CO2eq/personne/jour' },
                    { type: 'Activités fortement émettrices', factor: 19, unite: 'kg CO2eq/personne/jour' },
                    { type: 'Activités très fortement émettrices', factor: 49, unite: 'kg CO2eq/personne/jour' }
                ],
                hebergement: [
                    { type: 'Hotel haut de gamme (suite)', factor: 8.4, unite: ' kg CO2eq/chambre/jour' },
                    { type: 'Hôtel et môtel', factor: 4.2, unite: ' kg CO2eq/chambre/jour' },
                    { type: 'Maison', factor: 4.2, unite: ' kg CO2eq/chambre/jour' },
                    { type: 'Auberge', factor: 3.2, unite: ' kg CO2eq/chambre/jour' },
                    { type: 'Camping', factor: 0.03, unite: ' kg CO2eq/site/jour' },

                ],
                vehiculeType: [
                    { type: 'Sous-compacte', factor: 0.1816409225, consommation_l_100km: 7.84033333333333 },
                    { type: 'Compacte', factor: 0.178663202459016, consommation_l_100km: 7.71180327868853 },
                    { type: 'Berline', factor: 0.220170815151515, consommation_l_100km: 9.50343434343434 },
                    { type: 'VUS compact', factor: 0.206932696518987, consommation_l_100km: 8.9320253164557 },
                    { type: 'VUS grand format, Pickup et Fourgonnette', factor: 0.294552847297297, consommation_l_100km: 12.7140540540541 },
                    { type: 'Voiture hybride branchable', factor: 0.0743076501136364, consommation_l_100km: 3.20740909090909 },
                    { type: 'Voiture électrique', factor: 9.8909191820006E-07, consommation_l_100km: 4.26930794518209E-05 },
                    { type: 'Camion cargo', factor: 0.3405767296875, consommation_l_100km: 14.700625 },
                    { type: 'Camion cube', factor: 0.3978944665625, consommation_l_100km: 14.700625 },
                    { type: 'Camion 10 roues', factor: 0.723444484659091, consommation_l_100km: 26.7284090909091 },
                    { type: 'Semi-remorque', factor: 1.07903584152542, consommation_l_100km: 39.8661016949153 },
                ],
                logementType: [
                    { type: '2,5', factor: 35, unite: 'm2' },
                    { type: '3,5', factor: 45, unite: 'm2' },
                    { type: '4,5', factor: 60, unite: 'm2' },
                    { type: '5,5', factor: 80, unite: 'm2' },
                    { type: '6,5', factor: 100, unite: 'm2' },
                    { type: 'Maison (moins de 3 chambres)', factor: 130, unite: 'm2' },
                    { type: 'Maison (3 à 5 chambres)', factor: 200, unite: 'm2' },
                    { type: 'Maison (6 chambres et plus)', factor: 250, unite: 'm2' },
                    { type: 'Maison de luxe', factor: 350, unite: 'm2' }
                ],
                eventPortee: [
                    { type: 'Locale (15 km)', factor: 2.14875, unite: 'Émissions GES kg CO2eq / personne' },
                    { type: 'Proximité (40 km)', factor: 6.338475, unite: 'Émissions GES kg CO2eq / personne' },
                    { type: 'Régionale (150 km)', factor: 23.29425, unite: 'Émissions GES kg CO2eq / personne' },
                    { type: 'Nationale (1 500 km)', factor: 47.84376, unite: 'Émissions GES kg CO2eq / personne' },
                    { type: 'Internationale (5 000 km)', factor: 112.5315018, unite: 'Émissions GES kg CO2eq / personne' },
                    { type: 'Internationale + (10 000 km)', factor: 310.14451545, unite: 'Émissions GES kg CO2eq / personne' },
                ]
            }
        }
    },
    methods: {
        calculateResults() {
            switch (JSON.parse(sessionStorage.getItem('who'))) {
                case 'Un touriste':
                    if (sessionStorage.getItem('formType') == 'simple') {
                        let nombreAdulte = JSON.parse(sessionStorage.getItem('touristNbPerson'))['Adultes']
                        let nombreEnfant = JSON.parse(sessionStorage.getItem('touristNbPerson'))['Enfants']
                        let duree = JSON.parse(sessionStorage.getItem('touristNbJours'))['Jours']

                        //Provenance
                        let provenanceFactor = this.getProvenance(parseInt(sessionStorage.getItem('touristDistanceKm')))
                        let provenanceEmissions = provenanceFactor * (nombreAdulte + nombreEnfant / 2)

                        //Calcul Alimentation
                        let alimentationType = this.calculatorData.alimentation.find(element => element.type === JSON.parse(sessionStorage.getItem('touristAlimentation')))
                        let alimentationEmissions = (alimentationType.factor / 365) * (nombreAdulte + nombreEnfant / 2) * duree
                        //Calcul Activity
                        let activiteType = this.calculatorData.activite.find(element => element.type === JSON.parse(sessionStorage.getItem('touristActivity')))
                        let activiteEmissions = activiteType.factor * (nombreAdulte + nombreEnfant / 2) * duree

                        let emissionsTotal = alimentationEmissions + activiteEmissions + provenanceEmissions

                        return (emissionsTotal / 1000).toFixed(2);
                    }
                    else if (sessionStorage.getItem('formType') == 'detaillee') {
                        let nombreAdulte = JSON.parse(sessionStorage.getItem('touristNbPerson'))['Adultes']
                        let nombreEnfant = JSON.parse(sessionStorage.getItem('touristNbPerson'))['Enfants']
                        let duree = JSON.parse(sessionStorage.getItem('touristNbJours'))['Jours']

                        //1. Trajet pour atteindre votre destination et Déplacements durant votre séjour
                        let trajets = JSON.parse(sessionStorage.getItem('touristDistanceKm'))
                        let destinationEmissions = 0
                        Object.keys(trajets).forEach( key => {
                           let factor = this.calculatorData.vehiculeType.find(element=>element.type==key).factor
                           if(factor===undefined){
                            factor=this.calculatorData.transportVoyage.find(element=>element.type==key).factor
                           }
                           let aller = trajets[key].aller * factor
                           let retour = trajets[key].retour * factor
                           let local = trajets[key].troisRivieres * factor

                           destinationEmissions+= aller+retour+local
                        })
                        //3. Alimentation
                        let alimentation = JSON.parse(sessionStorage.getItem('touristAlimentation'))
                        let totalAlimentationEmissions = 0
                        Object.keys(alimentation).forEach(key => {
                            let factor
                            if (key === 'Viande à chaque repas') {
                                factor = this.calculatorData.alimentation.find(element=>element.type==='Amateur de viande').factor
                            }
                            else{
                                factor = this.calculatorData.alimentation.find(element=>element.type===key).factor
                            }
                            let alimentationEmissions = alimentation[key]/100  * factor/ 365 * (nombreAdulte + nombreEnfant) * duree
                            totalAlimentationEmissions += alimentationEmissions
                        })
                        //4. Activités
                        let activity = JSON.parse(sessionStorage.getItem('touristActivity'))
                        let totalActivityEmissions = 0
                        Object.keys(activity).forEach(key => {
                            let factor = this.calculatorData.activite.find(element=>element.type===key).factor
                            let alimentationEmissions = factor * activity[key]/100 * (nombreAdulte + nombreEnfant/2) * duree
                            totalActivityEmissions += alimentationEmissions
                        })
                        //5. Hébergement
                        let lodging = JSON.parse(sessionStorage.getItem('touristLodging'))
                        let totalLodgingEmissions = 0
                        Object.keys(lodging).forEach( key => {
                            let factor = this.calculatorData.hebergement.find(element=>element.type===key).factor
                            let lodgingEmissions = factor * lodging[key].temps * (duree - 1) * lodging[key].nbChambre
                            totalActivityEmissions+=lodgingEmissions
                        })

                        // return emissionsTotal
                        let emissionsTotal = destinationEmissions+totalAlimentationEmissions + totalActivityEmissions + totalLodgingEmissions
                        return (emissionsTotal / 1000).toFixed(2);
                    }
                    break;
                case 'Un évènement':
                    if (sessionStorage.getItem('formType') == 'simple') {
                        let evenementNbPerson = sessionStorage.getItem('evenementNbPerson')
                        let evenementPortee = JSON.parse(sessionStorage.getItem('evenementPortee'))
                        let eventPortee = this.calculatorData.eventPortee.find(element => element.type === evenementPortee)


                        let emissionsTotal = evenementNbPerson * eventPortee.factor

                        return (emissionsTotal / 1000).toFixed(2);

                    }
                    else if (sessionStorage.getItem('formType') == 'detaillee') {
                        let duree = JSON.parse(sessionStorage.getItem('evenementDuree'))['Jours']
                        let evenementNbParticipants = sessionStorage.getItem('evenementNbParticipants')
                        let evenementDistanceChoice = JSON.parse(sessionStorage.getItem('evenementDistanceChoice'))
                        let evenementDistancePartnerChoice = JSON.parse(sessionStorage.getItem('evenementDistancePartnerChoice'))
                        let evenementDistanceConference = JSON.parse(sessionStorage.getItem('evenementDistanceConference'))
                        
                        if(evenementDistanceChoice==="Avec la distance moyenne parcourue par personne"){
                            console.log(duree)
                        }
                        else if(evenementDistanceChoice==="Avec les distances réelles parcourues par votre public"){
                            console.log(evenementNbParticipants)
                        }
                        if(evenementDistancePartnerChoice==="Avec la distance moyenne parcourue par personne"){
                            console.log('test')
                        }
                        else if(evenementDistancePartnerChoice==="Avec les distances réelles parcourues par toutes les personnes"){
                            console.log('test')
                        }
                        if(evenementDistanceConference==="Avec la distance moyenne parcourue par personne"){
                            console.log('test')
                        }
                        else if(evenementDistanceConference==="Avec les distances réelles parcourues par toutes les personnes"){
                            console.log('test')
                        }
                    }
                    break;
                case 'Un citoyen':
                    if (sessionStorage.getItem('formType') == 'simple') {
                        console.log('test')
                    }
                    else if (sessionStorage.getItem('formType') == 'detaillee') {
                        console.log('test')
                    }
                    break;
                case 'Une organisation':
                    if (sessionStorage.getItem('formType') == 'simple') {
                        console.log('test')
                    }
                    else if (sessionStorage.getItem('formType') == 'detaillee') {
                        console.log('test')
                    }
                    break;
                default:
                    break;
            }
        },
        getProvenance(value) {
            let factor
            if (value < 15) {
                factor = this.calculatorData.provenanceVoyageur[0].factor;
            } else if (value >= 15 && value < 50) {
                factor = this.calculatorData.provenanceVoyageur[1].factor;
            } else if (value >= 50 && value < 250) {
                factor = this.calculatorData.provenanceVoyageur[2].factor;
            } else if (value >= 250 && value < 500) {
                factor = this.calculatorData.provenanceVoyageur[3].factor;
            } else if (value >= 500 && value < 1500) {
                factor = this.calculatorData.provenanceVoyageur[4].factor;
            } else if (value >= 1500 && value < 5000) {
                factor = this.calculatorData.provenanceVoyageur[5].factor;
            } else if (value >= 5000 && value < 10000) {
                factor = this.calculatorData.provenanceVoyageur[6].factor;
            } else if (value >= 10000) {
                factor = this.calculatorData.provenanceVoyageur[7].factor;
            }
            return factor
        }
    },
    components: {
        HeaderResults,
        FooterResults,
        BodyResults
    },
    mounted() {
        this.result = this.calculateResults()
        console.log(this.result)
    }
}
</script>
<style scoped>
.results-right-container {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 40%;
    z-index: 1000;
    background-size: cover;
    right: 0;
}

.footer {
    position: absolute;
    bottom: 0;
}
</style>