<template>
    <div class="light-color-bg position-relative">
        <QuestionTitle number="01" :question="question"></QuestionTitle>
        <div class="container-70">
            <InputBox :img="imgEvenement" :inputInfo="inputInfo" textAlign="end" v-model="result"></InputBox>
            <div @click="saveAndContinue('evenementNbPerson', JSON.stringify(result), './question-2')" class="btn-primary d-block w-100 mt-50px text-center">Suivant</div>
            <AvancementLine percent="20%" class="mt-50px"></AvancementLine>
        </div>
    </div>
</template>
<script>
import QuestionTitle from '@/components/QuestionTitle.vue'
import InputBox from '@/components/InputBox.vue'
import AvancementLine from '@/components/AvancementLine.vue'
/*Mixins*/
import utils from '@/mixins/utils'
export default {
    data() {
        return {
            result:null,
            question:'Combien y’a-t-il de personnes présentes pendant toute la durée de votre évènement? ',
            imgEvenement:require('@/assets/images/party.png'),
            inputInfo:{
                label:'participants(e)s, employés(e)s, artistes, etc.'
            },
        }
    },
    components: {
        QuestionTitle,
        InputBox,
        AvancementLine
    },
    mixins:[utils]
}
</script>