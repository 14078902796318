<template>
    <div class="modal-container">
        <div class="modal">
            <CustomIcon name="close" class="modal-closer" @click="$emit('closeModal')"></CustomIcon>
            <slot></slot>
        </div>
    </div>
</template>
<script>
import CustomIcon from "@/components/CustomIcon.vue"
export default{
    components:{
        CustomIcon
    }
}
</script>
<style scoped>
.modal-container{
    height: 100vh;
    width:100vw;
    position:fixed;
    top:0;
    left:0;
    background-color: rgba(245, 245, 245,0.5);
    display:flex;
    align-items: center;
    justify-content: center;
    z-index:10000;
}
.modal{
    padding:44px;
    border-radius: 5px;
    background: var(--light-blue, #CCEAED);
    max-width:750px;
}
.modal-closer{
    cursor:pointer;
    float: right;
}
</style>