<template>
    <div class="white-container">
        <div class="row">
            <div v-for="(energy, index) in energies" :key="index" class="col-50">
            <ConsommationEnergetiqueInput :energy="energy"></ConsommationEnergetiqueInput>
            </div>
        </div>
    </div>
</template>
<script>
import ConsommationEnergetiqueInput from '@/components/form/ConsommationEnergetiqueInput.vue'
export default {
    data() {
        return {
            energies: [
                {
                    label: 'Électricité',
                    icon: require('@/assets/images/electricity.png'),
                    unit: 'kWh'
                }, {
                    label: 'Propane',
                    icon: require('@/assets/images/propane.png'),
                    unit: 'kWh'
                }, {
                    label: 'Essence',
                    icon: require('@/assets/images/essence.png'),
                    unit: 'kWh'
                }, {
                    label: 'Gaz naturel',
                    icon: require('@/assets/images/gaz_naturel.png'),
                    unit: 'kWh'
                }, {
                    label: 'Mazout',
                    icon: require('@/assets/images/mazout.png'),
                    unit: 'kWh'
                }, {
                    label: 'Diesel',
                    icon: require('@/assets/images/diesel.png'),
                    unit: 'kWh'
                }
            ]
        }

    },
    components:{
        ConsommationEnergetiqueInput
    }
}
</script>