const utils = {
    methods: {
        radiansToDegrees(radians) {
            return radians * (180 / Math.PI);
        },
        saveAndContinue(label, value, next){
            sessionStorage.setItem(label, value);
            this.$router.push(next);
        }
    }
}

export default utils